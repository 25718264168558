import {createSlice} from '@reduxjs/toolkit';

const alertviewSlice = createSlice({
  name: 'alertview',
  initialState: {
    loading: true,
    vehiclesUnfiltered: [],
    vehicleSNDict: {},
    zonesDicts: {
      blocks: {},
      fields: {},
      regions: {},
    },
    geoFencesDict: {},
    taskConfigDict: {},
  },
  reducers: {
    updateLoading(state, action) {
      state.loading = action.payload;
    },
    updateVehiclesUnfilteredData(state, action) {
      const vehiclesUnfiltered = action.payload;

      const vehicleSNDict = {};
      vehiclesUnfiltered.forEach((vehicle) => {
        vehicleSNDict[vehicle.serialNumber] = vehicle;
      });

      state.vehiclesUnfiltered = vehiclesUnfiltered;
      state.vehicleSNDict = vehicleSNDict;
    },
    updateZonesData(state, action) {
      const zones = action.payload;
      zones.blocks.sort((a, b) => {
        return a.block_name.localeCompare(b.block_name);
      });
      zones.fields.sort((a, b) => {
        return a.field_name.localeCompare(b.field_name);
      });
      zones.regions.sort((a, b) => {
        return a.region_name.localeCompare(b.region_name);
      });

      const blocksDict = {};
      zones.blocks.forEach((block) => {
        blocksDict[block.block_id] = block;
      });
      const fieldsDict = {};
      zones.fields.forEach((field) => {
        fieldsDict[field.field_id] = field;
      });
      const regionsDict = {};
      zones.regions.forEach((region) => {
        regionsDict[region.region_id] = region;
      });
      state.zonesDicts = {
        blocks: blocksDict,
        fields: fieldsDict,
        regions: regionsDict,
      };
    },
    updateGeofences(state, action) {
      const geofences = action.payload;
      const geoFencesDict = {};
      geofences.forEach((geofence) => {
        geoFencesDict[geofence.properties.intelliblock_num] = geofence;
      });
      state.geoFencesDict = geoFencesDict;
    },
    updateTaskConfigDict(state, action) {
      state.taskConfigDict = action.payload;
    },
  },
});

function getAlertsData() {
  return async (dispatch, getState) => {
    dispatch(alertviewSlice.actions.updateLoading(true));

    // const getAlertsDataRequest = fetch('/shopview/getAlertsData', {cache: 'no-store'});

    const getVehiclesUnfilteredRequest = fetch('/getVehiclesUnfiltered', {cache: 'no-store'});
    const getZonesRequest = fetch('/getZonesDataUnfiltered', {cache: 'no-store'});
    const getGeofencesRequest = fetch('/getGeoFences', {cache: 'no-store'});
    const getTaskConfigsRequest = fetch('/getTaskConfigs', {cache: 'no-store'});

    // Make all calls
    const [getVehiclesUnfilteredResponse, getZonesResponse, getGeofencesResponse, getTaskConfigsResponse] =
      await Promise.all([getVehiclesUnfilteredRequest, getZonesRequest, getGeofencesRequest, getTaskConfigsRequest]);

    const vehiclesUnfiltered = await getVehiclesUnfilteredResponse.json();
    const zones = await getZonesResponse.json();
    const geofences = await getGeofencesResponse.json();
    const taskConfigIdDict = await getTaskConfigsResponse.json();

    dispatch(alertviewSlice.actions.updateVehiclesUnfilteredData(vehiclesUnfiltered));
    dispatch(alertviewSlice.actions.updateZonesData(zones.data));
    dispatch(alertviewSlice.actions.updateGeofences(geofences.data));
    dispatch(alertviewSlice.actions.updateTaskConfigDict(taskConfigIdDict));

    dispatch(alertviewSlice.actions.updateLoading(false));
  };
}

export {getAlertsData};
export default alertviewSlice.reducer;
