import React, {useState, useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  sendGAEvent,
  fetchPostAuthSafe,
  unitsLengthSubmitConversion,
  unitsAreaConversion,
  unitsLengthDisplayConversion,
} from '../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  updateLoading,
  updateZoneInfoData,
  updateDisplayedTable,
  updateSortMethod,
  updateMapView,
  updateZoneZoom,
  updateCropTypesDict,
  updateCropTypesDropdownOptions,
  updateEditZoneInfo,
} from './zoneInfoSlice';

function Menu(props) {
  const dispatch = useDispatch();
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const parentFieldLookup = useSelector((state) => {
    return state.zoneinfo.parentFieldLookup;
  });
  const parentRegionLookup = useSelector((state) => {
    return state.zoneinfo.parentRegionLookup;
  });
  const zonesData = useSelector((state) => {
    return state.zoneinfo.zonesData;
  });
  const geoFenceIntelliBlockDict = useSelector((state) => {
    return state.zoneinfo.geoFenceIntelliBlockDict;
  });
  const geoFenceAttributesIntelliBlockDict = useSelector((state) => {
    return state.zoneinfo.geoFenceAttributesIntelliBlockDict;
  });
  const sortMethod = useSelector((state) => {
    return state.zoneinfo.sortMethod;
  });
  const editZoneInfo = useSelector((state) => {
    return state.zoneinfo.editZoneInfo;
  });
  const editZoneInfoValues = useSelector((state) => {
    return state.zoneinfo.editZoneInfoValues;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [locate, setLocate] = useState('');

  useEffect(() => {
    getZoneInfoData();
  }, []);

  useEffect(() => {
    getCropTypesData();
  }, []);

  function toggleTableDisplay(table) {
    dispatch(updateDisplayedTable(table));
    sendGAEvent('coverage_table_view', table, 'zoneInfo');
  }

  async function getZoneInfoData() {
    dispatch(updateLoading(true));
    dispatch(updateEditZoneInfo(false));
    // Get cropview information
    const getZonesDataRequest = fetch('/getZonesData', {cache: 'no-store'});
    const getGeoFencesRequest = fetch('/getGeoFences', {cache: 'no-store'});
    const getGeoFenceAttributesRequest = fetch('/getGeoFenceAttributes', {cache: 'no-store'});

    // Make all calls
    const [getZonesDataResponse, getGeoFencesResponse, getGeoFenceAttributesResponse] = await Promise.all([
      getZonesDataRequest,
      getGeoFencesRequest,
      getGeoFenceAttributesRequest,
    ]);

    // Get zones data
    const getZonesDataResult = await getZonesDataResponse.json();
    const zonesData = getZonesDataResult.data;

    // Get geoFences data
    const getGeoFencesResult = await getGeoFencesResponse.json();
    const geoFences = getGeoFencesResult.data;
    const getGeoFenceAttributesResult = await getGeoFenceAttributesResponse.json();
    const geoFenceAttributes = getGeoFenceAttributesResult.data;

    dispatch(
      updateZoneInfoData({
        zonesData,
        geoFences,
        geoFenceAttributes,
      })
    );
    dispatch(updateZoneZoom(locate));
    dispatch(updateLoading(false));
  }

  async function getCropTypesData() {
    // Get crop type information
    const getCropTypesRequest = fetch('/getCropTypeData', {cache: 'no-store'});

    // Make all calls
    const [getCropTypesResponse] = await Promise.all([getCropTypesRequest]);
    const getCropTypesResult = await getCropTypesResponse.json();
    const cropTypesDict = getCropTypesResult.data;

    // Create create types dropdown dict
    // Pre-build the dropdown options now rather then on edits
    // as these are static
    const options = [];
    const primaryCropTypes = Object.keys(cropTypesDict);
    const cropSelectDict = {};

    // Options are group by primary crop type
    // See react-select grouping for more info
    primaryCropTypes.forEach((primaryCropType) => {
      cropSelectDict[primaryCropType] = [];

      cropTypesDict[primaryCropType].subClassCropsList.forEach((subCropType) => {
        cropSelectDict[primaryCropType].push({
          label: subCropType,
          value: {'subClass': subCropType, 'primaryClass': primaryCropType},
        });
      });
    });

    primaryCropTypes.forEach((primaryCropType) => {
      options.push({label: primaryCropType, options: cropSelectDict[primaryCropType]});
    });

    // Add the not specified option
    options.unshift({
      label: 'Not Specified',
      options: [{label: 'N/S', value: {'subClass': 'N/S', 'primaryClass': 'N/S'}}],
    });

    dispatch(updateCropTypesDict(cropTypesDict));
    dispatch(updateCropTypesDropdownOptions(options));
  }

  function downloadCsv() {
    const blocks = [];
    for (let i = 0; i < zonesData.blocks.length; i++) {
      let rowSpacingMeters = '';
      if (typeof zonesData.blocks[i].row_spacing_meters != 'undefined') {
        rowSpacingMeters = zonesData.blocks[i].row_spacing_meters;
      }
      const blockName = zonesData.blocks[i].block_name;
      const fieldName = parentFieldLookup[blockName];
      const regionName = parentRegionLookup[fieldName];

      const blockData = {
        'block_name': blockName,
        'field_name': fieldName,
        'region_name': regionName,
        'row_spacing_meters': rowSpacingMeters,
        'area_meters2': 0,
      };

      // Find area of the block
      let blockArea = 0;
      const blockIntelliBlockNums = zonesData.blocks[i].intelliblock_nums;
      for (let n = 0; n < blockIntelliBlockNums.length; n++) {
        let geoFence;
        let geoFenceAttribute;

        if (Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[n])) {
          geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[n]];
        }
        if (Object.prototype.hasOwnProperty.call(geoFenceAttributesIntelliBlockDict, blockIntelliBlockNums[n])) {
          geoFenceAttribute = geoFenceAttributesIntelliBlockDict[blockIntelliBlockNums[n]];
        }

        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          typeof geoFenceAttribute != 'undefined' &&
          typeof geoFenceAttribute.estimatedArea != 'undefined';

        if (geofenceRowPass) {
          blockArea += geoFenceAttribute.estimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (
        Object.prototype.hasOwnProperty.call(zonesData.blocks[i], 'manual_area_meters2') &&
        zonesData.blocks[i].manual_area_meters2 !== 0
      ) {
        blockArea = zonesData.blocks[i].manual_area_meters2;
      }

      blockData.area_meters2 = blockArea;
      blocks.push(blockData);
    }

    // Sort the categorized data by operation time
    blocks.sort((a, b) => {
      return a.block_name.localeCompare(b.block_name);
    });

    // Push the block based data into the csv data table
    const csvList = [];
    for (let i = 0; i < blocks.length; i++) {
      let rowSpacing = '';
      if (typeof blocks[i].row_spacing_meters === 'number') {
        rowSpacing =
          unitsLengthSystem == 'imperial'
            ? unitsLengthDisplayConversion(blocks[i].row_spacing_meters, 'ft').toFixed(2)
            : blocks[i].row_spacing_meters.toFixed(2);
      }

      let acreageConverted = unitsAreaConversion(blocks[i].area_meters2, 'ac', 'meters2');
      if (unitsAreaSystem == 'hectare') {
        acreageConverted = unitsAreaConversion(blocks[i].area_meters2, 'ha', 'meters2');
      }

      csvList.push([
        [
          `\"${blocks[i].block_name}\"`,
          `\"${blocks[i].field_name}\"`,
          `\"${blocks[i].region_name}\"`,
          acreageConverted.toFixed(2),
          rowSpacing,
        ],
      ]);
    }
    const rowSpacingUnits = unitsLengthSystem == 'imperial' ? 'ft' : 'm';

    // Construct the final csv by delimiting the sorted data array
    let csv = `Block Name,` + `Field Name,` + `Region Name,` + `Acreage (ac),Row Spacing (${rowSpacingUnits})\n`;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'zoneInfo.csv';
    hiddenElement.click();

    sendGAEvent('csv_download', 'zoneInfo', 'zoneInfo');
  }

  async function toggleEditMode() {
    if (editZoneInfo === false) {
      dispatch(updateEditZoneInfo(true));
    } else {
      // Convert rowspacing to meters
      const submitValues = JSON.parse(JSON.stringify(editZoneInfoValues));
      if (unitsLengthSystem == 'imperial') {
        Object.keys(submitValues).forEach((blockId) => {
          if (
            typeof submitValues[blockId].row_spacing_meters !== 'undefined' &&
            submitValues[blockId].row_spacing_meters !== ''
          ) {
            submitValues[blockId].row_spacing_meters = unitsLengthSubmitConversion(
              submitValues[blockId].row_spacing_meters,
              'ft'
            );
          }
        });
      }

      // Perform updates
      if (Object.keys(submitValues).length > 0) {
        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submitValues),
        };

        await fetchPostAuthSafe('/settings/updateBlockData', options, userSettings.username, userSettings.databaseName);
        await getZoneInfoData();
      }
      dispatch(updateEditZoneInfo(false));
    }
  }

  // The class names with 'md' are specifically for small screen sizes (< 767 px)
  return (
    <React.Fragment>
      <div className='row my-1 mx-0'>
        <div className='btn-group col-6 col-md-auto px-0 px-md-1'>
          <button
            type='button'
            disabled={editZoneInfo ? true : false}
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              toggleTableDisplay('block');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' size='xs' />
          </button>
          <button
            type='button'
            disabled={editZoneInfo ? true : false}
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              toggleTableDisplay('field');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' />
          </button>
          <button
            type='button'
            disabled={editZoneInfo ? true : false}
            className='btn border-dark btn-light cropview-menu-text'
            onClick={() => {
              toggleTableDisplay('region');
            }}
          >
            <FontAwesomeIcon icon='fas fa-square' size='xl' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1 dropdown' style={{maxWidth: '12.5%'}}>
          <button className='btn border-dark btn-light col-12 cropview-menu-text' data-toggle='dropdown'>
            <FontAwesomeIcon icon='fas fa-sort-amount-down' />
          </button>
          <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
            <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
            <button
              className='dropdown-item cropview-menu-text px-3'
              onClick={() => {
                dispatch(updateSortMethod('name'));
              }}
            >
              Name {sortMethod == 'name' && <FontAwesomeIcon icon='fas fa-check' />}
            </button>
            <button
              className='dropdown-item cropview-menu-text px-3'
              onClick={() => {
                dispatch(updateSortMethod('acreage'));
              }}
            >
              Acreage {sortMethod == 'acreage' && <FontAwesomeIcon icon='fas fa-check' />}
            </button>
          </div>
        </div>
        <div className='col-2 px-0 d-inline-block d-md-none' style={{maxWidth: '12.5%'}}>
          <button
            type='button'
            className='btn border-dark btn-light col-12 cropview-menu-text'
            onClick={() => {
              dispatch(updateMapView());
            }}
          >
            <FontAwesomeIcon icon='fas fa-map' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
          <button
            disabled={editZoneInfo ? true : false}
            type='button'
            className='btn border-dark btn-light col-12 cropview-menu-text'
            onClick={downloadCsv}
          >
            <FontAwesomeIcon icon='fas fa-download' />
          </button>
        </div>
        <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
          <button
            type='button'
            className={`btn border-dark col-12 cropview-menu-text ${editZoneInfo ? 'btn-primary' : 'btn-light'}`}
            onClick={toggleEditMode}
          >
            <FontAwesomeIcon icon='fas fa-pen-to-square' />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}

export {Menu};
