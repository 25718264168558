import React, {useState, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {DateTime} from 'luxon';

import {Map, useMap} from '@vis.gl/react-google-maps';
import {ScatterplotLayer, GeoJsonLayer, PathLayer, IconLayer, TextLayer} from '@deck.gl/layers';
import {GoogleMapsOverlay} from '@deck.gl/google-maps';
import {unitsAreaConversion, unitsLengthDisplayConversion} from '../../../app/utils';

// Colors to be used for plotting paths
const zoneColorsList = ['#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];

function DeckGLOverlay(props) {
  const map = useMap();
  const overlay = useMemo(() => {
    return new GoogleMapsOverlay(props);
  }, []);

  useEffect(() => {
    overlay.setMap(map);
    return () => {
      return overlay.setMap(null);
    };
  }, [map]);

  overlay.setProps(props);

  return null;
}

function MapControlButtons(props) {
  const map = useMap();
  const ref = useRef();

  useEffect(() => {
    if (map && ref) {
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(ref.current);
    }
  }, [map, ref]);

  return (
    <div ref={ref}>
      <div>
        <button className='btn-lg bg-light mt-2 mr-2' onClick={props.showAll}>
          <FontAwesomeIcon icon='fas fa-map-marker-alt' fixedWidth />
        </button>
      </div>
      <div>
        <button
          className='btn-lg bg-light mr-2'
          onClick={() => {
            return props.setDisplayZones(!props.displayZones);
          }}
        >
          <FontAwesomeIcon icon={props.displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
        </button>
      </div>
      {props.displayZones && (
        <React.Fragment>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZoneLabels();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2 mt-n1'
              style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
              onClick={() => {
                props.changeZonesColor();
              }}
            >
              <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
            </button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
}

function AlertViewMap(props) {
  const dispatch = useDispatch();

  const zonesDicts = useSelector((state) => {
    return state.alertview.zonesDicts;
  });
  const geoFencesDict = useSelector((state) => {
    return state.alertview.geoFencesDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  // Map states
  const [zoneColorIndex, setZoneColorIndex] = useState(0);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);

  const [zoneInfoWindow, setZoneInfoWindow] = useState(null);
  const [vehInfoWindow, setVehInfoWindow] = useState(null);
  const [loaded, setLoaded] = useState(null);

  const [inZonePathArrowData, setInZonePathArrowData] = useState([]);
  const [inZoneGpsPointsData, setInZoneGpsPointsData] = useState([]);
  const [geofenceLayerData, setGeofenceLayerData] = useState(null);
  const [labelLayerData, setLabelLayerData] = useState(null);

  const [displayZones, setDisplayZones] = useState(true);
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [pathExtremes, setPathExtremes] = useState({
    latMax: null,
    latMin: null,
    lonMax: null,
    lonMin: null,
  });

  const map = useMap();

  useEffect(() => {
    if (map != null) {
      showAll();
    }
  }, [pathExtremes, map]);

  useEffect(() => {
    if (map != null) {
      zoomToLocation();
    }
  }, [props.zoomLocation]);

  // Onload function
  useEffect(() => {
    if (loaded) {
      const zoneInfoWin = new google.maps.InfoWindow();
      const vehInfoWin = new google.maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWin);
      setVehInfoWindow(vehInfoWin);

      map.addListener('zoom_changed', function () {
        setMapZoomLevel(map.getZoom());
      });

      setMapZoomLevel(map.getZoom());
    }
  }, [loaded]);

  // Update path extremes
  useEffect(() => {
    const tempPathExtremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    props.alertEventData.alertEventList.forEach((alertEvent) => {
      alertEvent.path.forEach((point) => {
        const lat = point.latitude;
        const lng = point.longitude;

        if (lat > tempPathExtremes.latMax || tempPathExtremes.latMax == null) {
          tempPathExtremes.latMax = lat;
        }
        if (lat < tempPathExtremes.latMin || tempPathExtremes.latMin == null) {
          tempPathExtremes.latMin = lat;
        }
        if (lng > tempPathExtremes.lngMax || tempPathExtremes.lngMax == null) {
          tempPathExtremes.lngMax = lng;
        }
        if (lng < tempPathExtremes.lngMin || tempPathExtremes.lngMin == null) {
          tempPathExtremes.lngMin = lng;
        }
      });
    });

    setPathExtremes(tempPathExtremes);
  }, [props.alertEventData]);

  // Generate data for paths points and arrows
  useEffect(() => {
    const gps = props.alertEventData.alertEventList.flatMap((d) => {
      const scatterData = d.path.map((point) => {
        return {
          alertEventId: d.id,
          vehicle: props.alertEventData.vehicle,
          position: [point.longitude, point.latitude],
          dateTime: point.datetime,
          speed: point.speed,
        };
      });
      return scatterData;
    });

    const pathArrow = props.alertEventData.alertEventList.flatMap((d) => {
      const pathArray = d.path.map((point) => {
        return [point.longitude, point.latitude];
      });

      return generateArrowPositions(pathArray, 0.00073).map((pos) => {
        return {
          vehicle: props.alertEventData.vehicle,
          position: pos,
        };
      });
    });

    setInZoneGpsPointsData(gps);
    setInZonePathArrowData(pathArrow);
  }, [props.alertEventData]);

  // Generate data for geofence and label layers
  useEffect(() => {
    if (typeof zonesDicts.blocks === 'undefined') {
      return;
    }

    // Use geojson layer becaus eof multipolygons, after new zone management is implement
    // consider using polygon layer since geofence structure changed
    const geojsonBase = {
      type: 'FeatureCollection',
      name: 'Geofences Layer',
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:OGC:1.3:CRS84',
        },
      },
      features: [],
    };

    // Create list for labels
    const labelData = [];

    // Iterate through blocks
    Object.values(zonesDicts.blocks).forEach((block) => {
      // const fieldName = zonesDicts.fields[block.fieldId] ? zonesDicts.fields[block.fieldId].name : '';
      // const regionName = zonesDicts.regions[block.regionId] ? zonesDicts.regions[block.regionId].name :  '';

      // Find area of the block
      let blockArea = 0;
      const blockIntelliBlockNums = block.intelliblock_nums;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFencesDict[blockIntelliBlockNums[j]];

        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          geoFence.properties.rowPassEstimatedArea;
        if (geofenceRowPass) {
          blockArea += geoFence.properties.rowPassEstimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (Object.prototype.hasOwnProperty.call(block, 'manual_area_meters2') && block.manual_area_meters2 !== 0) {
        blockArea = block.manual_area_meters2;
      }

      // const blockIntelliBlockNums = block.intelliblock_nums;

      // Get center point of block
      const latCenter = (block.lat_max + block.lat_min) / 2;
      const lngCenter = (block.lng_max + block.lng_min) / 2;

      // Determine acreage
      // Convert acreage area to desired units
      let unitsArea = 'ac';
      if (unitsAreaSystem == 'hectare') {
        unitsArea = 'ha';
      }
      const convertedAcreage = unitsAreaConversion(blockArea, unitsArea, 'meters2');
      const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;

      // Iterate through each multipolygon for plotting
      let labelAdded = false;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFencesDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFencesDict[blockIntelliBlockNums[j]];

        // Determine row spacing
        let rowSpacingWithUnit = 'N/A';
        if (geoFence.properties.row_spacing_meters > 0) {
          rowSpacingWithUnit =
            unitsLengthSystem == 'imperial'
              ? `${(geoFence.properties.row_spacing_meters * 3.28084).toFixed(2)} ft`
              : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
        }

        // Add to label list, once per block
        if (!labelAdded) {
          labelAdded = true;
          labelData.push({
            position: [lngCenter, latCenter],
            blockName: geoFence.properties.block_name,
            fieldName: geoFence.properties.field_name,
            regionName: geoFence.properties.region_name,
            rowSpacingWithUnit: rowSpacingWithUnit,
            acreageWithUnit: acreageWithUnit,
          });
        }

        // Add additional data to geofences needed for displaying
        const newGeofenceObj = {...geoFence};
        newGeofenceObj.rowSpacingWithUnit = rowSpacingWithUnit;
        newGeofenceObj.acreageWithUnit = acreageWithUnit;

        geojsonBase.features.push(newGeofenceObj);
      }
    });

    setGeofenceLayerData(geojsonBase);
    setLabelLayerData(labelData);
  }, [geoFencesDict, zonesDicts]);

  // Function to generate arrow positions
  const generateArrowPositions = (path, spacing) => {
    const positions = [];
    let noArrowDistance = 0;

    for (let i = 0; i < path.length - 1; i++) {
      const start = path[i];
      const end = path[i + 1];
      const distance = Math.hypot(end[0] - start[0], end[1] - start[1]);
      const angle = 90 - Math.atan2(end[0] - start[0], end[1] - start[1]) * (180 / Math.PI);
      const numArrows = Math.floor(distance / spacing);

      if (numArrows == 0) {
        if (noArrowDistance + distance > spacing) {
          // Inject an arrow if the total distance without arrow in this path is over the spacing
          const x = start[0] + 0.5 * (end[0] - start[0]);
          const y = start[1] + 0.5 * (end[1] - start[1]);
          positions.push([x, y, angle]);
          noArrowDistance = 0;
        } else {
          noArrowDistance += distance;
        }
      } else {
        noArrowDistance = 0;
      }

      for (let j = 0; j < numArrows; j++) {
        const ratio = (j + 1) / (numArrows + 1); // Position of the arrow
        const x = start[0] + ratio * (end[0] - start[0]);
        const y = start[1] + ratio * (end[1] - start[1]);
        positions.push([x, y, angle]);
      }
    }

    return positions;
  };

  // Button control functions
  function showAll() {
    if (pathExtremes.latMin == null) {
      return;
    }
    const sw = {lat: pathExtremes.latMin, lng: pathExtremes.lngMin};
    const ne = {lat: pathExtremes.latMax, lng: pathExtremes.lngMax};

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function zoomToLocation() {
    if (props.zoomLocation.latMin == null) {
      return;
    }
    const sw = {lat: props.zoomLocation.latMin, lng: props.zoomLocation.lngMin};
    const ne = {lat: props.zoomLocation.latMax, lng: props.zoomLocation.lngMax};

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 5 : 3;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function changeZonesColor() {
    setZoneColorIndex((zoneColorIndex + 1) % zoneColorsList.length);
  }

  // Layers for map
  const layers = [
    new GeoJsonLayer({
      id: 'geofences',
      data: geofenceLayerData,
      visible: displayZones,
      stroked: true,
      filled: true,
      pickable: true,
      getFillColor: (geofence) => {
        let hex = zoneColorsList[zoneColorIndex];
        if (geofence.reiWarningShow) {
          hex = '#af4a4d';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        rgb.push(70); // Opacity
        return rgb;
      },
      getLineColor: (geofence) => {
        const hex = zoneColorsList[zoneColorIndex];
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getLineWidth: (geofence) => {
        const strokeWeight = 0.2;
        return strokeWeight;
      },
      onClick: (clickedObj) => {
        const geofence = clickedObj.object;

        // Create info window content
        let infoWindowContent = `<div><b>${geofence.properties.block_name}</b></div>`;

        // Display remaining rei
        if (geofence.reiWarningShow) {
          infoWindowContent +=
            `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
            `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${geofence.remRei} hours</div>`;
        }

        // Display field and region
        infoWindowContent +=
          `<div style="font-size:10px"><b>Field:</b> ${geofence.properties.field_name}</div>` +
          `<div style="font-size:10px"><b>Region:</b> ${geofence.properties.region_name}</div>`;

        // Display zone info
        if (zoneInfoEnabled) {
          infoWindowContent +=
            `<div style="font-size:10px"><b>Row Spacing:</b> ${geofence.rowSpacingWithUnit}</div>` +
            `<div style="font-size:10px"><b>Acreage:</b> ${geofence.acreageWithUnit}</div>`;
        }

        if (geofence) {
          zoneInfoWindow.setContent(infoWindowContent);
          zoneInfoWindow.setPosition(new google.maps.LatLng(clickedObj.coordinate[1], clickedObj.coordinate[0]));
          zoneInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          zoneInfoWindow.close();
        }
      },
      updateTriggers: {
        getFillColor: [zoneColorIndex],
        getLineColor: [zoneColorIndex],
      },
    }),

    // In Zone Layers
    new PathLayer({
      id: `path`,
      data: props.alertEventData.alertEventList,
      getColor: (alertEvent) => {
        let hex = '#0fff00';
        if (alertEvent.id == props.selectedAlertEventId) {
          hex = '#ff0000';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });

        return rgb;
      },
      getPath: (d) => {
        const pathArray = d.path.map((point) => {
          return [point.longitude, point.latitude];
        });

        return pathArray;
      },
      getWidth: 1,
      opacity: 0.7,
      widthUnits: 'meters',
      widthMinPixels: 2,
      widthMaxPixels: 3,
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const infoWindowContent = `<div>${hoverObj.object.vehicle}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getColor: [props.selectedAlertEventId],
      },
    }),

    new IconLayer({
      id: `arrows`,
      data: inZonePathArrowData,
      getIcon: () => {
        return {
          url: '/img/arrow.png', // Your arrow icon URL
          width: 100,
          height: 100,
        };
      },
      getPosition: (d) => {
        return [d.position[0], d.position[1]];
      },
      getAngle: (d) => {
        return d.position[2];
      },
      sizeScale: 10,
      sizeUnits: 'meters',
      sizeMinPixels: 0,
      sizeMaxPixels: 15,
    }),

    new ScatterplotLayer({
      id: `gps`,
      data: inZoneGpsPointsData,
      radiusUnits: 'meters',
      getRadius: 1,
      opacity: 0.5,
      getFillColor: (point) => {
        let hex = '#0fff00';
        if (point.alertEventId == props.selectedAlertEventId) {
          hex = '#ff0000';
        }
        const rgb = hex.match(/[0-9a-f]{2}/g).map((x) => {
          return parseInt(x, 16);
        });
        return rgb;
      },
      getPosition: (d) => {
        return d.position;
      },
      pickable: true,
      onHover: (hoverObj) => {
        if (hoverObj.picked) {
          const pointSpeed =
            unitsLengthSystem == 'imperial'
              ? `${unitsLengthDisplayConversion(hoverObj.object.speed, 'mph').toFixed(1)} mph`
              : `${hoverObj.object.speed} km/h`;
          const pointDateTimeStr = DateTime.fromISO(hoverObj.object.dateTime)
            .setZone(customerSettings.general.timeZone)
            .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

          const infoWindowContent =
            `<div><b>${hoverObj.object.vehicle}</b></div>` +
            `<div style="font-size:10px"><b>Speed:</b> ${pointSpeed}</div>` +
            `<div style="font-size:10px"><b>Date:</b> ${pointDateTimeStr}</div>`;
          vehInfoWindow.setContent(infoWindowContent);
          vehInfoWindow.setPosition(new google.maps.LatLng(hoverObj.coordinate[1], hoverObj.coordinate[0]));
          vehInfoWindow.open({
            map,
            shouldFocus: false,
          });
        } else {
          vehInfoWindow.close();
        }
      },
      updateTriggers: {
        getFillColor: [props.selectedAlertEventId],
      },
    }),

    new TextLayer({
      id: 'labels',
      data: labelLayerData,
      visible: displayZones && mapZoomLevel > 14,
      getPosition: (labelData) => {
        return labelData.position;
      },
      getText: (labelData) => {
        let labelText = labelData.blockName;
        if (zoneLabelsIndex == 1) {
          labelText = labelData.fieldName;
        } else if (zoneLabelsIndex == 2) {
          labelText = labelData.regionName;
        } else if (zoneLabelsIndex == 3) {
          labelText = `Row spacing: ${labelData.rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          labelText = `Acreage: ${labelData.acreageWithUnit}`;
        } else {
          // Default to block name if index is invalid
          labelText = labelData.blockName;
        }

        return labelText;
      },
      getColor: [0, 0, 0],
      background: true,
      backgroundPadding: [3, 3],
      getBackgroundColor: [255, 255, 255, 128],
      getSize: 12,
      maxWidth: 6,
      fontFamily: 'sans-serif',
      fontWeight: 'bold',
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center',
      updateTriggers: {
        getText: [zoneLabelsIndex],
      },
    }),
  ];

  return (
    <React.Fragment>
      <Map
        defaultCenter={{lat: 37.9718, lng: -122.7651}}
        defaultZoom={9}
        mapTypeId={'hybrid'} // Set map type to satellite
        gestureHandling={'greedy'}
        disableDefaultUI={true} // Optional: removes default UI controls
        styles={[]} // Optional: empty styles array to ensure no style overrides
        reuseMaps={false}
      >
        <DeckGLOverlay
          layers={layers}
          onLoad={() => {
            setLoaded(true);
          }}
        />
        <MapControlButtons
          displayZones={displayZones}
          setDisplayZones={setDisplayZones}
          changeZoneLabels={changeZoneLabels}
          changeZonesColor={changeZonesColor}
          showAll={showAll}
        />
      </Map>
    </React.Fragment>
  );
}

export {AlertViewMap};
