import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import {MuiChipsInput} from 'mui-chips-input';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {StaticTimePicker} from '@mui/x-date-pickers/StaticTimePicker';
import {
  fetchPostAuthSafe,
  emailRegex,
  dollarRegex,
  sortVehicleNamesHelper,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  ModalSubmitting,
  ViewOnlyText,
  CollapsableSection,
  GeneralSearchSelect,
  InlineTooltip,
} from '../../../components/GeneralComponents';
import {Divider} from '@mui/material';
import {deepCopy} from '../../../app/utils';
import {DateTime} from 'luxon';

function ReportEditModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehicles = useSelector((state) => {
    return state.settings.vehiclesUnfiltered;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const reportConfigsDict = useSelector((state) => {
    return state.settings.reportConfigsDict;
  });
  const databaseUsers = useSelector((state) => {
    return state.settings.databaseUsers;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const zones = useSelector((state) => {
    return state.settings.zones;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const viewOnly = !userSettings.roleAccess['reportsManagement'];
  // Allow user with NO filters or ic_admin to use non-user based filters
  // TODO - FULL ACCESS is given to all user that have access to reports management, pending design revision
  const fullFilterAccess =
    [
      ...userSettings.general.excludedVehicles,
      ...userSettings.general.excludedBlocks,
      ...userSettings.general.excludedFields,
      ...userSettings.general.excludedRegions,
    ].length == 0 ||
    userSettings.role == 'ic_admin' ||
    true;

  // Determine OEM access to know which features to allow / block
  const deviceOEMProviders = customerSettings.general.deviceOEMProviders;
  const samsaraToken = customerSettings.general.samsaraToken;
  const geotabSupport = deviceOEMProviders.includes('geotab');
  const validSamsaraToken = samsaraToken != '' && samsaraToken != null && samsaraToken !== undefined;
  const samsaraSupport = deviceOEMProviders.includes('samsara') && validSamsaraToken;

  const defaultCoverageSettings = {
    efficiencyPage: false,
    blocksPage: false,
    diagnosticsPage: false,
    flaggedLoggersPage: true,
    acresLegend: true,
    fieldCoverageUser: true,
    fieldCoverageImplement: false,
    fieldCoverageTask: true,
    coverageShowVehicleAverageSpeed: true,
    coverageHideInactiveVehicles: true,
    flaggedLoggersIgnoreNotActive: false,
    excludeBesols: false,
    vehicleSummarySort: 'alphanumeric',
    fieldSummarySort: 'alphanumeric',
    blockPagesSort: 'alphanumeric',
    timeInFieldInclusionThresholdMinutes: 0.5,
  };

  const defaultEquipmentSettings = {
    showGeotabAlertLink: false,
    diagnosticsPage: true,
    equipmentShowMostRecentLocation: 'Blocks',
    exceptionsList: [],
  };

  const defaultReportConfig = {
    name: '',
    disabled: false,
    reportType: 'coverage',
    configType: 'user',
    schedule: '0 0 * * *',
    range: 'daily',
    useDeliveryUserList: true,
    delivery: {
      method: 'pdf',
      toUsers: [],
      toEmails: [],
      bccEmails: [],
    },
    settings: {
      ...defaultCoverageSettings,
    },
    filters: {
      useUserFilters: true,
      taskSelection: 'all',
      excludedVehicles: [],
      excludedBlockIds: [],
      excludedFieldIds: [],
      excludedRegionIds: [],
    },
  };

  const coverageToggles = {
    'efficiencyPage': {
      displayName: 'Efficiency Page',
      tooltip: 'Include Efficiency Page in Coverage Report.',
    },
    'blocksPage': {
      displayName: 'Blocks Page',
      tooltip: 'Include Blocks Coverage Page in Coverage Report.',
    },
    'flaggedLoggersPage': {
      displayName: 'Flagged Loggers Page',
      tooltip: 'Include Flagged Loggers Page in Coverage Report.',
    },
    'acresLegend': {
      displayName: `${unitsAreaSystem == 'acre' ? 'Acres' : 'Hectares'} Legend`,
      tooltip: `Display ${unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'} Legend in Coverage Report.`,
    },
    'fieldCoverageUser': {
      displayName: 'Field Coverage User',
      tooltip: 'Show User that started the Task in Field Coverage Summary in Coverage Report.',
    },
    'fieldCoverageImplement': {
      displayName: 'Field Coverage Implement',
      tooltip: 'Show Implement used for the Task in Field Coverage Summary in Coverage Report.',
    },
    'fieldCoverageTask': {
      displayName: 'Field Coverage Task',
      tooltip: 'Show Selected Task in Field Coverage Summary in Coverage Report.',
    },
    'coverageShowVehicleAverageSpeed': {
      displayName: 'Vehicle Average Speed',
      tooltip: 'Show Average Vehicle Speed in Field Coverage Summary in Coverage Report.',
    },
    'coverageHideInactiveVehicles': {
      displayName: 'Hide Inactive Vehicles',
      tooltip: 'Hide Inactive Vehicle (Vehicles with no traversal time/task duration) in Coverage Report.',
    },
    'flaggedLoggersIgnoreNotActive': {
      displayName: 'Ignore Not Active Flagged Loggers',
      tooltip: 'Exclude Not Active Device in Flagged Loggers Page in Coverage Report.',
    },
    'excludeBesols': {
      displayName: 'Exclude Besols',
      tooltip: 'Exclude Besols Device Vehicle in Coverage Report.',
    },
  };

  const coverageSortAndThresholds = {
    'vehicleSummarySort': {
      displayName: 'Vehicle Summary Sort Method',
      tooltip: 'Sort Method of Vehicle Summary Page in Coverage Report.',
    },
    'fieldSummarySort': {
      displayName: 'Field Summary Sort Method',
      tooltip: 'Sort Method of Field Summary Page in Coverage Report.',
    },
    'blockPagesSort': {
      displayName: 'Block Page Sort Method',
      tooltip: 'Sort Method of Block Page in Coverage Report.',
    },
    'timeInFieldInclusionThresholdMinutes': {
      displayName: 'Time In Field Inclusion Threshold(min)',
      tooltip: 'Minimum time (in minutes) of in field activity in a Block to be included in Coverage Report.',
    },
  };

  const equipmentToggles = {
    'showGeotabAlertLink': {
      displayName: 'Geotab Alert Links',
      tooltip: 'Include Geotab Alert Links in Alerts Page of Equipment Report.',
    },
    'diagnosticsPage': {
      displayName: 'Diagnostics Page',
      tooltip: 'Include Diagnostics Page in Equipment Report.',
    },
  };

  const [editReportConfig, setEditReportConfig] = useState({...defaultReportConfig});
  const [submitStatus, setSubmitStatus] = useState('pending');
  // submitStatus:  "pending" : Filling form
  //                "submitting" : Submitting
  //                "success" : Submission Completed
  //                "error" : Error submission
  const [modalError, setModalError] = useState('');
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [warningBubbleVisible, setWarningBubbleVisible] = useState(false);
  const [userEmailCount, setUserEmailCount] = useState(0);

  const [locale, setLocale] = React.useState('en-us');
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const editModalOpen = props.editModalOpen;

  useEffect(() => {
    // Reset State of Edit Modal
    setSubmitStatus('pending');
    setShowAdvancedSettings(false);
    setWarningBubbleVisible(false);
    setUserEmailCount(0);
    setModalError('');
    if (props.editReportConfig) {
      setEditReportConfig({
        ...defaultReportConfig,
        ...props.editReportConfig,
        delivery: {
          ...defaultReportConfig.delivery,
          ...props.editReportConfig.delivery,
        },
      });
    } else {
      setEditReportConfig({
        ...defaultReportConfig,
        settings: {...defaultCoverageSettings},
      });
    }
  }, [props.editReportConfig]);

  useEffect(() => {
    setWarningBubbleVisible(!editReportConfig.filters.useUserFilters);
  }, [editReportConfig.filters.useUserFilters]);

  useEffect(() => {
    handleUpdateEditReportConfig('filters.useUserFilters', editReportConfig.useDeliveryUserList);
  }, [editReportConfig.useDeliveryUserList]);

  useEffect(() => {
    const usernameToEmailDict = {};
    if (databaseUsers) {
      databaseUsers.forEach((user) => {
        usernameToEmailDict[user.username] = user.email;
      });

      const userEmailSet = [
        ...new Set(
          editReportConfig.delivery.toUsers.map((username) => {
            return usernameToEmailDict[username];
          })
        ),
      ];

      setUserEmailCount(userEmailSet.length);
    }
  }, [databaseUsers, editReportConfig.delivery.toUsers]);

  useEffect(async () => {
    const tempVehicleOptions = [];
    for (let i = 0; i < vehicles.length; i++) {
      if (vehicles[i].icActive) {
        const vehicleSN = vehicles[i].serialNumber.toLowerCase();

        const vehicleDisabled = userSettings.general.excludedVehicles.includes(vehicleSN);
        if (editReportConfig.filters.excludedVehicles.includes(vehicleSN)) {
          console.log('vehicles have excluded vehicle');
        }

        if (editReportConfig.filters.excludedVehicles.includes(vehicleSN) || !vehicleDisabled) {
          tempVehicleOptions.push({
            label: vehicles[i].name + (vehicles[i].archived ? ' (Archived)' : ''),
            value: vehicleSN,
            disabled: vehicleDisabled,
          });
        }
      }
    }
    tempVehicleOptions.sort((a, b) => {
      return sortVehicleNamesHelper(a.label, b.label);
    });
    setVehicleOptions(tempVehicleOptions);
  }, [vehicles, userSettings]);

  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: smallScreen ? '100%' : 1000,
    maxWidth: '90vw',
    maxHeight: smallScreen ? '100%' : '90vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: 'auto',
    p: 2,
  };

  function emptyAction(props) {
    // use to override time picker active buttons
    return null;
  }

  async function handleEditSubmit() {
    let shouldSubmit = true;
    let updateDoc = editReportConfig;
    if (!editReportConfig.name || editReportConfig.name.trim() == '') {
      setModalError('Report Config Name cannot be empty.');
      return;
    }

    // If reportConfig is being updated
    if (editReportConfig.configId != undefined) {
      // Check if reportConfig is updated by lodash object comparsion
      shouldSubmit = !_.isEqual(editReportConfig, reportConfigsDict[editReportConfig.configId]);
      updateDoc = {configId: editReportConfig.configId};
      Object.keys(reportConfigsDict[editReportConfig.configId]).forEach((key) => {
        if (!_.isEqual(reportConfigsDict[editReportConfig.configId][key], editReportConfig[key])) {
          updateDoc[key] = editReportConfig[key];
        }
      });
    }
    if (shouldSubmit) {
      setSubmitStatus('submitting');
      const result = await postReportConfigUpdates(updateDoc);
      setEditReportConfig({...defaultReportConfig});
      dispatch(props.getReportConfigsData());
      setSubmitStatus('success');
    }
  }

  function modalClose() {
    setSubmitStatus('pending');
    props.handleResetEditReportConfig();
    setModalError('');
    return props.handleEditModalOpen(false);
  }

  function handleUpdateEditReportConfig(path, value) {
    setEditReportConfig((config) => {
      const newConfig = deepCopy(config); // Create a deep Copy of the config
      let tempObj = newConfig; // This reference always points to the level containing the value
      const pList = path.split('.');
      const len = pList.length;
      for (let i = 0; i < len - 1; i++) {
        const elem = pList[i];
        if (tempObj[elem] == undefined) tempObj[elem] = {};
        tempObj = tempObj[elem]; // move down the nest level
      }

      tempObj[pList[len - 1]] = value; // Update the value in the new copy object with reference
      return newConfig;
    });
  }

  function generateCoverageSettingsToggles() {
    return (
      <React.Fragment>
        {Object.keys(coverageToggles).map((key) => {
          return (
            <div key={key} className='col-sm-6 col-md-4 p-1'>
              <div className='d-flex align-items-center m-1 bg-light rounded border border-light h-100 w-100 shadow-sm'>
                <div className='p-2 w-100'>
                  <strong>{coverageToggles[key].displayName}</strong>
                  <InlineTooltip
                    title={coverageToggles[key].tooltip}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </div>
                <div className='p-2 flex-shrink-1'>
                  <Switch
                    disabled={viewOnly}
                    name={`settings.${key}`}
                    checked={editReportConfig.settings[key]}
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  function generateCoverageSettingsSortsAndThresholds() {
    const sortOptions = {
      vehicleSummarySort: [
        {value: 'alphanumeric', label: 'Alphanumeric'},
        {value: 'time', label: 'Time'},
        {value: 'type', label: 'Vehicle Type'},
      ],
      fieldSummarySort: [
        {value: 'alphanumeric', label: 'Alphanumeric'},
        {value: 'time', label: 'Time'},
      ],
      blockPagesSort: [
        {value: 'alphanumeric', label: 'Alphanumeric'},
        {value: 'time', label: 'Time'},
      ],
    };

    return (
      <React.Fragment>
        {Object.keys(coverageSortAndThresholds).map((key) => {
          return (
            <div key={key} className='col-md-6 p-1'>
              <div className='d-flex align-items-center m-1 bg-light rounded border border-light h-100 w-100 shadow-sm'>
                <div className='p-2 w-100'>
                  <strong>{coverageSortAndThresholds[key].displayName}</strong>
                  <InlineTooltip
                    title={coverageSortAndThresholds[key].tooltip}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </div>
                <div className='p-2 flex-shrink-1'>
                  {key == 'timeInFieldInclusionThresholdMinutes' ? (
                    <TextField
                      disabled={viewOnly}
                      name={`settings.${key}`}
                      type='text'
                      value={editReportConfig.settings[key]}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value && (!dollarRegex.test(value) || value.includes('-'))) {
                          return;
                        }
                        handleUpdateEditReportConfig(e.target.name, e.target.value);
                      }}
                      onKeyDown={handleAllowOnlyNumbers}
                    />
                  ) : (
                    <Select
                      multiple={false}
                      disabled={viewOnly}
                      name={`settings.${key}`}
                      value={editReportConfig.settings[key]}
                      onChange={(e) => {
                        handleUpdateEditReportConfig(e.target.name, e.target.value);
                      }}
                    >
                      {sortOptions[key].map((option, idx) => {
                        return (
                          <MenuItem key={idx} value={option.value}>
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  function generateEquipmentSettingsToggles() {
    return (
      <React.Fragment>
        {Object.keys(equipmentToggles).map((key) => {
          return (
            <div key={key} className='col-sm-6 col-md-4 p-1'>
              <div className='d-flex align-items-center m-1 bg-light rounded border border-light h-100 w-100 shadow-sm'>
                <div className='p-2 w-100'>
                  <strong>{equipmentToggles[key].displayName}</strong>
                  <InlineTooltip
                    title={equipmentToggles[key].tooltip}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </div>
                <div className='p-2 flex-shrink-1'>
                  <Switch
                    disabled={viewOnly}
                    name={`settings.${key}`}
                    checked={editReportConfig.settings[key]}
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  function generateEquipmentMostRecentLocationSelect() {
    const mostRecentLocationOptions = [
      {value: '', label: 'Do Not Show'},
      {value: 'Blocks', label: 'Blocks'},
      {value: 'Fields', label: 'Fields'},
      {value: 'Regions', label: 'Regions'},
    ];

    return (
      <div className='col-sm-6 col-md-4 p-1'>
        <div className='d-flex align-items-center m-1 bg-light rounded border border-light h-100 w-100 shadow-sm'>
          <div className='p-2 w-100'>
            <strong>Equipment Show Most Recent Location</strong>
          </div>
          <div className='p-2 flex-shrink-1'>
            <Select
              multiple={false}
              name={`settings.equipmentShowMostRecentLocation`}
              value={editReportConfig.settings.equipmentShowMostRecentLocation}
              onChange={(e) => {
                handleUpdateEditReportConfig(e.target.name, e.target.value);
              }}
            >
              {mostRecentLocationOptions.map((option, idx) => {
                return (
                  <MenuItem key={idx} value={option.value}>
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        </div>
      </div>
    );
  }

  function generateExceptionListInput() {
    return (
      <div className='col-sm-12 px-1'>
        <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
          <label className='mb-0 mt-2'>
            <strong>Exceptions List</strong>
          </label>
          <div className='mt-1'>
            <MuiChipsInput
              disabled={viewOnly}
              size='small'
              fullWidth
              hideClearAll
              disableDeleteOnBackspace
              name={'settings.exceptionsList'}
              value={editReportConfig.settings.exceptionsList}
              onChange={(value) => {
                const tempValues = [...value];
                handleUpdateEditReportConfig('settings.exceptionsList', tempValues);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  function generateVehicleFilters() {
    return (
      <GeneralSearchSelect
        multiple={true}
        name={`filters.excludedVehicles`}
        value={editReportConfig.filters.excludedVehicles}
        options={vehicleOptions}
        handleChange={(e) => {
          handleUpdateEditReportConfig(e.target.name, e.target.value);
        }}
      />
    );
  }

  function generateTaskSelections() {
    return (
      <GeneralSearchSelect
        multiple={false}
        name={`filters.taskSelection`}
        value={editReportConfig.filters.taskSelection}
        options={renderTaskOptions()}
        handleChange={(e) => {
          handleUpdateEditReportConfig(e.target.name, e.target.value);
        }}
      />
    );
  }

  /**
   *
   * @param {*} updateDoc
   */
  async function postReportConfigUpdates(updateDoc) {
    const postData = updateDoc;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
      body: JSON.stringify(postData),
    };
    const url = '/settings/createUpdateReportConfigs';

    const response = await fetchPostAuthSafe(url, options, userSettings.username, userSettings.databaseName);
    const result = await response.json();
    if (result.errorMsg) {
      navigate('/error', {state: {errorMsg: result.errorMsg}});
    }

    if (result.status == 401) {
      navigate('/error', {state: {errorMsg: 'Unauthorized Access or Action Detected, Please try again'}});
    }

    return result;
  }

  function renderTaskOptions() {
    const options = [];
    options.push({
      value: 'all',
      label: 'All',
    });

    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        options.push({
          value: taskId,
          label: taskConfigDict[taskId].name,
        });
      }
    });

    return options;
  }

  if (submitStatus != 'pending') {
    return (
      <Modal
        open={editModalOpen}
        onClose={modalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={boxStyle}>
          <div className='flex-row mb-3 col-12 px-1 text-center'>
            {submitStatus == 'submitting' && <ModalSubmitting />}
            {submitStatus == 'success' && (
              <div>
                <div style={{color: 'green', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-check' />
                </div>
                <span>Update Completed.</span>
              </div>
            )}
            {submitStatus == 'error' && (
              <div>
                <div style={{color: 'red', fontSize: '5vw'}}>
                  <FontAwesomeIcon icon='fa-sharp fa-solid fa-circle-xmark' />
                </div>
                <span>An error has occured, please try again.</span>
              </div>
            )}
          </div>
          {submitStatus != 'submitting' && (
            <div style={{marginTop: '20px'}} className='flex-row mb-3 col-12 px-1'>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                <Button variant='contained' color='success' onClick={modalClose}>
                  Close
                </Button>
              </div>
            </div>
          )}
        </Box>
      </Modal>
    );
  }

  return (
    <Modal
      open={editModalOpen}
      onClose={modalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={boxStyle}>
        <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
          <h3>
            <strong>
              {`${editReportConfig?.configId != undefined ? 'Edit' : 'Create'}`} Report Configuration
              {editReportConfig?.configId != undefined &&
                props.editReportConfig?.name &&
                ` (${props.editReportConfig?.name || ''})`}
            </strong>
          </h3>
        </div>
        {viewOnly && <ViewOnlyText />}
        <div className='row mx-0 px-1 pb-3'>
          <div className='row col-md-7 m-0 p-0'>
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Report Name</strong>
                </label>
                <div className='mt-1'>
                  <TextField
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    value={editReportConfig.name}
                    name='name'
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Report Type</strong>
                </label>
                <div className='mt-1'>
                  <Select
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    value={editReportConfig.reportType}
                    name='reportType'
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.value);
                      console.log('changed report Type');
                      if (e.target.value == 'equipment') {
                        handleUpdateEditReportConfig('settings', defaultEquipmentSettings);
                      } else {
                        handleUpdateEditReportConfig('settings', defaultCoverageSettings);
                      }
                    }}
                  >
                    <MenuItem value={'coverage'}>Coverage</MenuItem>

                    {/* Only show Equipment if not a samsara only account */}
                    {!samsaraSupport && geotabSupport && <MenuItem value={'equipment'}>Equipment</MenuItem>}
                  </Select>
                </div>
              </div>
            </div>
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Delivery Method</strong>
                </label>
                <div className='mt-1'>
                  <Select
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    name='delivery.method'
                    value={editReportConfig.delivery.method}
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.value);
                    }}
                  >
                    <MenuItem value={'pdf'}>PDF</MenuItem>
                    <MenuItem value={'link'}>Link</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Task Selection</strong>
                  <InlineTooltip
                    title={`The Task to be included in this Report.`}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </label>
                <div className='mt-1'>{generateTaskSelections()}</div>
              </div>
            </div>
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Interval</strong>
                </label>
                <div className='mt-1'>
                  <Select
                    disabled={viewOnly}
                    size='small'
                    fullWidth
                    name='range'
                    value={editReportConfig.range}
                    onChange={(e) => {
                      const newInterval = e.target.value;
                      handleUpdateEditReportConfig(e.target.name, newInterval);
                      // Update Cron Schedule
                      const tempCronSchedule = editReportConfig.schedule.split(' ');
                      if (newInterval == 'weekly') {
                        if (tempCronSchedule[4] == '*') {
                          tempCronSchedule[4] = 0;
                        }
                      } else {
                        if (tempCronSchedule[4] != '*') {
                          tempCronSchedule[4] = '*';
                        }
                      }
                      const tempHourCron = tempCronSchedule[1].split('/')[0];
                      tempCronSchedule[1] = [tempHourCron, ...(newInterval == 'half' ? ['12'] : [])].join('/');
                      handleUpdateEditReportConfig('schedule', tempCronSchedule.join(' '));
                    }}
                  >
                    <MenuItem value={'half'}>12 Hrs</MenuItem>
                    <MenuItem value={'daily'}>Daily</MenuItem>
                    <MenuItem value={'weekly'}>Weekly</MenuItem>
                  </Select>
                </div>
              </div>
            </div>
            {editReportConfig.range == 'weekly' && (
              <div className='col-sm-6 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Day of Week</strong>
                  </label>
                  <div className='mt-1'>
                    <Select
                      disabled={viewOnly}
                      size='small'
                      fullWidth
                      name='schedule'
                      value={editReportConfig.schedule.split(' ')[4]}
                      onChange={(e) => {
                        const tempCronSchedule = editReportConfig.schedule.split(' ');
                        tempCronSchedule[4] = e.target.value;

                        handleUpdateEditReportConfig(e.target.name, tempCronSchedule.join(' '));
                      }}
                    >
                      {daysOfWeek.map((dayString, index) => {
                        return (
                          <MenuItem key={index} value={index}>
                            {dayString}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
            )}
            <div className='col-sm-6 px-1'>
              <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                <label className='mb-0 mt-2'>
                  <strong>Deliver Based on User</strong>
                  <InlineTooltip
                    title={`Deliver Report based on User Account instead of Email.`}
                    icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                  />
                </label>
                <div className='mt-1'>
                  <Switch
                    disabled={viewOnly}
                    name={'useDeliveryUserList'}
                    checked={editReportConfig.useDeliveryUserList}
                    onChange={(e) => {
                      handleUpdateEditReportConfig(e.target.name, e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
            {!editReportConfig?.useDeliveryUserList ? (
              <div className='col-sm-12 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Email</strong>
                    <InlineTooltip
                      title={`Emails Entered will receive this Report. `}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </label>
                  <div className='mt-1'>
                    <MuiChipsInput
                      disabled={viewOnly}
                      size='small'
                      fullWidth
                      hideClearAll
                      disableDeleteOnBackspace
                      name={'delivery.toEmails'}
                      value={editReportConfig.delivery.toEmails}
                      onChange={(value) => {
                        const tempValues = [...value];
                        handleUpdateEditReportConfig('delivery.toEmails', tempValues);
                      }}
                      validate={(newValue) => {
                        return {
                          isError: !emailRegex.test(newValue),
                          textError: 'Please enter an email address',
                        };
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className='col-sm-12 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>Username (Email)</strong>
                    <InlineTooltip
                      title={`The Users selected will receive this Report.`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                    {editReportConfig.delivery.toUsers.length > userEmailCount && ( // Duplicate Email from multiple Users
                      <InlineTooltip
                        title={`Multiple users selected have the same email address, duplicate reports may be sent.`}
                        icon={
                          <FontAwesomeIcon icon='fas fa-triangle-exclamation' size={'1x'} style={{color: '#f4c526'}} />
                        }
                      />
                    )}
                  </label>
                  <div className='mt-1'>
                    <GeneralSearchSelect
                      disabled={viewOnly}
                      multiple={true}
                      name={`delivery.toUsers`}
                      value={editReportConfig.delivery.toUsers}
                      options={databaseUsers
                        .filter((user) => {
                          return !user.archived || editReportConfig.delivery.toUsers.includes(user.username);
                        })
                        .map((user) => {
                          return {value: user.username, label: `${user.username} (${user.email})`};
                        })}
                      handleChange={(e) => {
                        handleUpdateEditReportConfig(e.target.name, e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {userSettings.role == 'ic_admin' && (
              <div className='col-sm-12 px-1'>
                <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                  <label className='mb-0 mt-2'>
                    <strong>bcc</strong>
                  </label>
                  <div className='mt-1'>
                    <MuiChipsInput
                      disabled={viewOnly}
                      size='small'
                      fullWidth
                      hideClearAll
                      disableDeleteOnBackspace
                      name={'delivery.bccEmails'}
                      value={editReportConfig.delivery.bccEmails}
                      onChange={(value) => {
                        const tempValues = [...value];
                        handleUpdateEditReportConfig('delivery.bccEmails', tempValues);
                      }}
                      validate={(newValue) => {
                        return {
                          isError: !emailRegex.test(newValue),
                          textError: 'Please enter an email address',
                        };
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-5 m-0 px-1 my-auto'>
            <div className='flex-row mb-3 col-12 m-1 p-1 bg-light rounded border border-light shadow-sm'>
              <label className='mb-0 mt-2'>
                <strong>Report Delivery Schedule</strong>
                <InlineTooltip
                  title={`An estimation of when the report will be delivered. 
                    Report content will be based on the organization's shift time and the Report's interval configured.
                    (Actual delivery may be delayed for up to 30minutes depending on amount of data require to be processed.)`}
                  icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                />
              </label>
              <div className='mt-1'>
                <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
                  <StaticTimePicker
                    slots={{
                      actionBar: emptyAction,
                    }}
                    minutesStep={10}
                    disabled={viewOnly}
                    size='small'
                    defaultValue={DateTime.now().set({
                      hour: editReportConfig.schedule.split(' ')[1].split('/')[0],
                      minute: editReportConfig.schedule.split(' ')[0],
                    })}
                    onChange={(e) => {
                      const tempCronSchedule = editReportConfig.schedule.split(' ');
                      tempCronSchedule[0] = e.minute;
                      const tempHourCron = tempCronSchedule[1].split('/');
                      if (tempHourCron.length > 1) {
                        tempCronSchedule[1] = [e.hour, tempHourCron[1]].join('/');
                      } else {
                        tempCronSchedule[1] = e.hour;
                      }
                      handleUpdateEditReportConfig('schedule', tempCronSchedule.join(' '));
                    }}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        {fullFilterAccess && (
          <div className='row mx-0 px-1 pb-3'>
            {!editReportConfig?.useDeliveryUserList == false && (
              <div className='row w-100 mx-0 px-0'>
                {/* Force 'Apply User Filter' to be its own row */}
                <div className='col-sm-6 col-md-4 px-1'>
                  <div className='d-flex align-items-center m-1 p-1 bg-light rounded border border-light shadow-sm'>
                    <div className='p-2 w-100'>
                      <strong>Apply User Filter</strong>
                      <InlineTooltip
                        title={`Toggle on to disable Report Configuration's filters and use the User's account filter. `}
                        icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                      />
                    </div>
                    <div className='p-2 flex-shrink-1'>
                      <Switch
                        disabled={viewOnly || editReportConfig?.useDeliveryUserList == false}
                        name={'filters.useUserFilters'}
                        checked={editReportConfig.filters.useUserFilters}
                        onChange={(e) => {
                          handleUpdateEditReportConfig(e.target.name, e.target.checked);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!editReportConfig.filters.useUserFilters && (
              <React.Fragment>
                <div className='row w-100 mx-0 px-0'>
                  <Collapse in={warningBubbleVisible} className='w-100 px-2'>
                    <Alert
                      action={
                        <IconButton
                          aria-label='close'
                          color='inherit'
                          size='small'
                          onClick={() => {
                            setWarningBubbleVisible(false);
                          }}
                        >
                          <CloseIcon fontSize='inherit' />
                        </IconButton>
                      }
                      variant='outlined'
                      severity='warning'
                      sx={{
                        '.MuiAlert-message': {
                          width: '100%',
                        },
                      }}
                    >
                      <AlertTitle sx={{textAlign: 'center'}}>{!smallScreen && 'Warning'}</AlertTitle>
                      <div style={{textAlign: 'center', width: '100%'}}>
                        {`User settings and filters will be overrode by Report Configuration settings and filters.`}
                      </div>
                    </Alert>
                  </Collapse>
                </div>
                <div className='row w-100 mx-0 px-0'>
                  <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                    <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                      <label className='mb-0 mt-2'>
                        <strong>Excluded Vehicles</strong>
                        <InlineTooltip
                          title={`The Vehicles to be excluded in this Report.`}
                          icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                        />
                      </label>
                      <div className='mt-1'>{generateVehicleFilters()}</div>
                    </div>
                  </div>
                </div>
                <div className='row w-100 mx-0 px-0'>
                  <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                    <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                      <label className='mb-0 mt-2'>
                        <strong>Excluded Blocks</strong>
                        <InlineTooltip
                          title={`The Blocks to be excluded in this Report.`}
                          icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                        />
                      </label>
                      <div className='mt-1'>
                        <GeneralSearchSelect
                          disabled={viewOnly}
                          multiple={true}
                          name={`filters.excludedBlockIds`}
                          value={editReportConfig.filters.excludedBlockIds}
                          options={zones.blocks.map((zone) => {
                            return {value: zone.block_id, label: zone.block_name};
                          })}
                          handleChange={(e) => {
                            handleUpdateEditReportConfig(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                    <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                      <label className='mb-0 mt-2'>
                        <strong>Excluded Fields</strong>
                        <InlineTooltip
                          title={`The Fields to be excluded in this Report.`}
                          icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                        />
                      </label>
                      <div className='mt-1'>
                        <GeneralSearchSelect
                          disabled={viewOnly}
                          multiple={true}
                          name={`filters.excludedFieldIds`}
                          value={editReportConfig.filters.excludedFieldIds}
                          options={zones.fields.map((zone) => {
                            return {value: zone.field_id, label: zone.field_name};
                          })}
                          handleChange={(e) => {
                            handleUpdateEditReportConfig(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='d-flex align-items-stretch col-sm-6 col-md-4 px-1'>
                    <div className='m-1 p-1 bg-light rounded border border-light w-100 shadow-sm'>
                      <label className='mb-0 mt-2'>
                        <strong>Excluded Regions</strong>
                        <InlineTooltip
                          title={`The Regions to be excluded in this Report.`}
                          icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                        />
                      </label>
                      <div className='mt-1'>
                        <GeneralSearchSelect
                          disabled={viewOnly}
                          multiple={true}
                          name={`filters.excludedRegionIds`}
                          value={editReportConfig.filters.excludedRegionIds}
                          options={zones.regions.map((zone) => {
                            return {value: zone.region_id, label: zone.region_name};
                          })}
                          handleChange={(e) => {
                            handleUpdateEditReportConfig(e.target.name, e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        )}
        <Divider />
        {/* Advanced Settings Collapsable */}
        <CollapsableSection
          title='Advanced Settings'
          collapseOpen={showAdvancedSettings}
          setCollapseOpen={setShowAdvancedSettings}
          section='Advanced Settings'
          viewOnly={viewOnly}
        >
          <div className='row mx-0 px-1 pb-3'>
            {editReportConfig.reportType == 'equipment' ? (
              <React.Fragment>
                <div className='row p-1 m-0'>
                  {generateEquipmentSettingsToggles()}
                  {generateEquipmentMostRecentLocationSelect()}
                </div>
                {!customerSettings.general.useNewAlertEvents && (
                  <div className='row p-1 m-0'>{generateExceptionListInput()}</div>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className='row p-1 m-0'>{generateCoverageSettingsToggles()}</div>
                <div className='row p-1 m-0'>{generateCoverageSettingsSortsAndThresholds()}</div>
              </React.Fragment>
            )}
          </div>
        </CollapsableSection>
        {editReportConfig.configId != undefined && (
          <React.Fragment>
            <Divider />
            <div className='row w-100 mx-auto px-0 py-2 justify-content-center'>
              <div className='col-sm-6 col-md-4 px-1'>
                <div className='d-flex align-items-center m-1 p-1 bg-light rounded border border-light shadow-sm'>
                  <div className='p-2 w-100'>
                    <strong>Archive Report Config</strong>
                    <InlineTooltip
                      title={`Toggle on and save to remove this Report Config.`}
                      icon={<FontAwesomeIcon icon='fas fa-info-circle' size={'1x'} style={{color: '#858796'}} />}
                    />
                  </div>
                  <div className='p-2 flex-shrink-1'>
                    <Switch
                      disabled={viewOnly}
                      name={'disabled'}
                      checked={editReportConfig.disabled}
                      onChange={(e) => {
                        handleUpdateEditReportConfig(e.target.name, e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        <div className='flex-row mb-3 col-12 px-1'>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
            <Button variant='ic-button' color='secondary' onClick={modalClose}>
              Cancel
            </Button>
            {!viewOnly && (
              <Button variant='ic-button' color='primary' onClick={handleEditSubmit}>
                Submit
              </Button>
            )}
          </div>
        </div>
        <div style={{color: 'red', textAlign: 'center'}} className='flex-row mb-3 col-12 px-1'>
          {modalError}
        </div>
      </Box>
    </Modal>
  );
}

export {ReportEditModal};
