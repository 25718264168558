import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createPortal} from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import * as turf from '@turf/turf';

import {polygonArea, unitsAreaConversion, unitsLengthDisplayConversion} from '../../app/utils';
import {updateZoneZoom} from './zoneInfoSlice';

// Label for adding onto map
const LabelComponent = ({text}) => {
  return (
    <label
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'center',
        minWidth: '25px',
      }}
    >
      {text}
    </label>
  );
};

// Control button div defined here so that it can be populated using jsx and then appended to the map once initialized
const controlButtonDiv = document.createElement('div');
// Global variables
let map = null; // Reference to google 'map' object
let maps = null; // Reference to google 'maps' object

const zoneColorsList = ['#af4a4d', '#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];
let zoneColorIndex = 0;
let zoneInfoWindow = null; // Single zone info window so that only one is open at a time

function Map(props) {
  const dispatch = useDispatch();

  const zonesData = useSelector((state) => {
    return state.zoneinfo.zonesData;
  });
  const geoFences = useSelector((state) => {
    return state.zoneinfo.geoFences;
  });
  const geoFenceIntelliBlockDict = useSelector((state) => {
    return state.zoneinfo.geoFenceIntelliBlockDict;
  });
  const zoneZoom = useSelector((state) => {
    return state.zoneinfo.zoneZoom;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [zoneExtremes, setZoneExtremes] = useState({
    latMax: null,
    latMin: null,
    lngMax: null,
    lngMin: null,
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayZones, setDisplayZones] = useState(true);
  const [displayRowBearing, setDisplayRowBearing] = useState(false);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [geoFencePolygons, setGeoFencePolygons] = useState([]);
  const [rowBearings, setRowBearings] = useState([]);
  const [rowBearingsText, setRowBearingsText] = useState([]);
  const [zoneLabels, setZoneLabels] = useState([]);

  useEffect(() => {
    if (map != null && maps != null) {
      updateZoneExtremes();
    }
  }, [zonesData]);

  useEffect(() => {
    if (map != null && maps != null) {
      plotGeoFences();
    }
  }, [geoFences, zonesData]);

  useEffect(() => {
    if (map != null && maps != null) {
      showGeoFences();
    }
  }, [displayZones]);

  useEffect(() => {
    // This updates the map to show row bearing
    // If the map zoom changes
    if (map != null && maps != null) {
      showRowBearing();
      map.addListener('bounds_changed', () => {
        showRowBearing();
      });
    }
  }, [displayZones, displayRowBearing, mapZoomLevel]);

  useEffect(() => {
    if (map != null && maps != null) {
      zoomToZone();
    }
  }, [zoneZoom]);

  useEffect(() => {}, [props.filters]);

  useEffect(() => {
    if (map != null && maps != null) {
      setZoneLabels(generateZoneLabels());
    }
  }, [zoneLabelsIndex, geoFences, zonesData]);

  // Options for google map appearance and functionality
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    rotateControl: false,
    tilt: 0,
  };

  function handleApiLoaded(gmap, gmaps) {
    // Set global 'map' and 'maps' objects to those received from the google api
    map = gmap;
    maps = gmaps;

    // Append custom control buttons to map
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);

    // Create infowindow object
    zoneInfoWindow = new maps.InfoWindow();
    map.addListener('click', () => {
      zoneInfoWindow.close();
    });

    map.addListener('zoom_changed', () => {
      setMapZoomLevel(map.getZoom());
    });

    // Update map with markers once initialized
    setMapZoomLevel(map.getZoom());
    plotGeoFences();
  }

  function updateZoneExtremes() {
    // Track extremes of zones
    const extremes = {
      latMax: null,
      latMin: null,
      lngMax: null,
      lngMin: null,
    };

    zonesData['regions'].forEach((region) => {
      if (extremes.latMax == null || region.lat_max > extremes.latMax) {
        extremes.latMax = region.lat_max;
      }
      if (extremes.latMin == null || region.lat_min < extremes.latMin) {
        extremes.latMin = region.lat_min;
      }
      if (extremes.lngMax == null || region.lng_max > extremes.lngMax) {
        extremes.lngMax = region.lng_max;
      }
      if (extremes.lngMin == null || region.lng_min < extremes.lngMin) {
        extremes.lngMin = region.lng_min;
      }
    });
    setZoneExtremes(extremes);
  }

  function plotGeoFences() {
    // Clear previous geoFences
    for (let i = 0; i < geoFencePolygons.length; i++) {
      geoFencePolygons[i].setMap(null);
    }

    // SHOULD REALLY COMBINE ALL THE MAP COMPONENTS INTO ONE AND CLEAN UP
    // Check if blocks within zoneData
    if (
      !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
      Object.keys(geoFenceIntelliBlockDict).length < 1
    ) {
      return;
    }

    const polygonsList = [];
    const rowBearingsList = [];
    const rowBearingsTextList = [];
    // Iterate through blocks
    for (let i = 0; i < zonesData.blocks.length; i++) {
      // Find area of the block
      let blockArea = 0;
      const blockIntelliBlockNums = zonesData.blocks[i].intelliblock_nums;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          geoFence.properties.rowPassEstimatedArea;
        if (geofenceRowPass) {
          blockArea += geoFence.properties.rowPassEstimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (
        Object.prototype.hasOwnProperty.call(zonesData.blocks[i], 'manual_area_meters2') &&
        zonesData.blocks[i].manual_area_meters2 !== 0
      ) {
        blockArea = zonesData.blocks[i].manual_area_meters2;
      }

      const lineSymbol1 = {
        path: google.maps.SymbolPath.FORWARD_OPEN_ARROW,
      };
      const lineSymbol2 = {
        path: google.maps.SymbolPath.BACKWARD_OPEN_ARROW,
      };

      // Iterate through each multipolygon for plotting
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];
        // Iterate through each polygon in the multipolygon
        for (let k = 0; k < geoFence.geometry.coordinates.length; k++) {
          const latLngList = [];
          // Iterate through each coordinate set in the polygon.
          // Sets after the first one correspond to holes inside the polygon
          let outerClockwise = false;
          for (let n = 0; n < geoFence.geometry.coordinates[k].length; n++) {
            const coordinates = geoFence.geometry.coordinates[k][n];

            const clockwise = polygonArea(coordinates) > 0;

            let latLngMap = coordinates.map((coordPair) => {
              return {lat: coordPair[1], lng: coordPair[0]};
            });

            if (n == 0) {
              outerClockwise = clockwise;
            } else if ((outerClockwise && clockwise) || (!outerClockwise && !clockwise)) {
              latLngMap = latLngMap.reverse();
            }

            latLngList.push(latLngMap);
          }
          const polygon = new maps.Polygon({
            paths: latLngList,
            strokeColor: zoneColorsList[zoneColorIndex],
            strokeWeight: 0.5,
            fillColor: zoneColorsList[zoneColorIndex],
            fillOpacity: 0.3,
            zIndex: -1,
          });
          polygon.setMap(map);
          polygonsList.push(polygon);

          if (polygon != undefined) {
            // Add info window
            let rowSpacingWithUnit = 'N/A';
            if (geoFence.properties.row_spacing_meters > 0) {
              rowSpacingWithUnit =
                unitsLengthSystem == 'imperial'
                  ? `${unitsLengthDisplayConversion(geoFence.properties.row_spacing_meters, 'ft').toFixed(2)} ft`
                  : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
            }

            // Convert acreage area to desired units
            let unitsArea = 'ac';
            if (unitsAreaSystem == 'hectare') {
              unitsArea = 'ha';
            }

            const convertedAcreage = unitsAreaConversion(blockArea, unitsArea, 'meters2');
            const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;
            const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';

            const infoWindowContent =
              `<div><b>${geoFence.properties.block_name}</b></div>` +
              `<div style="font-size:10px"><b>Field:</b> ${geoFence.properties.field_name}</div>` +
              `<div style="font-size:10px"><b>Region:</b> ${geoFence.properties.region_name}</div>` +
              `<div style="font-size:10px"><b>Row Spacing:</b> ${rowSpacingWithUnit}</div>` +
              `<div style="font-size:10px"><b>${areaLabel}:</b> ${acreageWithUnit}</div>`;

            polygon.addListener('click', (event) => {
              zoneInfoWindow.setContent(infoWindowContent);
              zoneInfoWindow.setPosition(event.latLng);
              zoneInfoWindow.open({
                map,
                shouldFocus: false,
              });
            });

            // Add row bearing
            if (
              geoFence.properties.rowBearing != -1 &&
              geoFence.properties.trustRowBearing &&
              geoFence.geometry.coordinates[k].length > 0
            ) {
              // Get bearing in degree
              const bearing = geoFence.properties.rowBearing;
              const bearingDir1 = bearing > 180 ? bearing - 180 : bearing; // Range 0 to 180
              const bearingDir2 = bearing - 180; // Range -180 to 0

              // Create polygon object and find center
              const outerCoords = geoFence.geometry.coordinates[k][0];
              const polygonObj = turf.polygon([outerCoords]);
              const polygonCenter = turf.center(polygonObj);

              // Create long line extending from center
              const point1 = turf.destination(polygonCenter, 5, bearingDir1, {units: 'kilometers'});
              const point2 = turf.destination(polygonCenter, 5, bearingDir2, {units: 'kilometers'});
              const lineString = turf.lineString([point1.geometry.coordinates, point2.geometry.coordinates]);

              // Get the midpoint of the LineString
              const midpoint = turf.midpoint(point1.geometry.coordinates, point2.geometry.coordinates);

              // Get intersections between line and polygon
              const intersections = turf.lineIntersect(lineString, polygonObj);

              // Create line points to plot
              const linePoints = [];
              intersections.features.forEach((feature) => {
                linePoints.push({
                  lng: feature.geometry.coordinates[0],
                  lat: feature.geometry.coordinates[1],
                });
              });

              // Create row bearing line
              const line = new maps.Polyline({
                path: linePoints,
                geodesic: true,
                strokeColor: '#66ff66',
                strokeOpacity: 0.75,
                strokeWeight: 2,
                icons: [
                  {
                    icon: lineSymbol1,
                    offset: '90%',
                  },
                  {
                    icon: lineSymbol2,
                    offset: '10%',
                  },
                ],
              });

              // Create a marker with a custom label
              // Displays the bearing value
              const marker = new maps.Marker({
                position: {lat: midpoint.geometry.coordinates[1], lng: midpoint.geometry.coordinates[0]},
                label: {
                  text: `${Math.round(bearing % 90)}°`,
                  color: 'black', // Set label text color
                  fontWeight: 'bold', // Set label text weight
                  fontSize: '12px',
                },
                icon: {
                  path: maps.SymbolPath.CIRCLE,
                  fillColor: '#ffffff', // Set the fill color
                  fillOpacity: 1, // Set the fill opacity
                  strokeColor: '#66ff66', // Set the outline color
                  strokeWeight: 2, // Set the outline thickness
                  scale: 12,
                },
              });

              marker.setMap(null);
              line.setMap(null);
              rowBearingsList.push(line);
              rowBearingsTextList.push(marker);
            }
          }
        }
      }
    }

    setGeoFencePolygons(polygonsList);
    setRowBearings(rowBearingsList);
    setRowBearingsText(rowBearingsTextList);
  }

  function generateZoneLabels() {
    let allZoneLabels = [];
    if (maps != null) {
      // Check if blocks within zoneData
      if (
        !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
        Object.keys(geoFenceIntelliBlockDict).length < 1
      ) {
        return;
      }

      allZoneLabels = zonesData.blocks.map((block, index) => {
        // Find area of the block
        let blockArea = 0;
        const blockIntelliBlockNums = block.intelliblock_nums;
        for (let j = 0; j < blockIntelliBlockNums.length; j++) {
          if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
            continue;
          }
          const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

          const geofenceRowPass =
            customerSettings.general.rowPassStatisticsEnabled &&
            geoFence.properties.rowPassEnabled &&
            geoFence.properties.trustRowBearing &&
            geoFence.properties.rowPassAnalysisAvailable &&
            geoFence.properties.rowPassEstimatedArea;
          if (geofenceRowPass) {
            blockArea += geoFence.properties.rowPassEstimatedArea;
          } else {
            blockArea += geoFence.properties.area_meters2;
          }
        }

        // Override block area if manually inputted area exists
        if (Object.prototype.hasOwnProperty.call(block, 'manual_area_meters2') && block.manual_area_meters2 !== 0) {
          blockArea = block.manual_area_meters2;
        }

        const latCenter = (block.lat_max + block.lat_min) / 2;
        const lngCenter = (block.lng_max + block.lng_min) / 2;

        // Get one geoFence of block
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[0])) {
          return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={''} />;
        }
        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[0]];

        let zoneName = geoFence.properties.block_name;
        if (zoneLabelsIndex == 1) {
          zoneName = geoFence.properties.field_name;
        } else if (zoneLabelsIndex == 2) {
          zoneName = geoFence.properties.region_name;
        } else if (zoneLabelsIndex == 3) {
          let rowSpacingWithUnit = 'N/A';
          if (geoFence.properties.row_spacing_meters > 0) {
            rowSpacingWithUnit =
              unitsLengthSystem == 'imperial'
                ? `${unitsLengthDisplayConversion(geoFence.properties.row_spacing_meters, 'ft').toFixed(2)} ft`
                : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
          }
          zoneName = `Row spacing: ${rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4) {
          // Convert acreage area to desired units
          let unitsArea = 'ac';
          if (unitsAreaSystem == 'hectare') {
            unitsArea = 'ha';
          }

          const convertedAcreage = unitsAreaConversion(blockArea, unitsArea, 'meters2');
          const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          zoneName = `${areaLabel}: ${acreageWithUnit}`;
        }
        return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={zoneName} />;
      });
    }
    return allZoneLabels;
  }

  function showAll() {
    if (zoneExtremes.latMin == null) {
      return;
    }
    const sw = {lat: zoneExtremes.latMin, lng: zoneExtremes.lngMin};
    const ne = {lat: zoneExtremes.latMax, lng: zoneExtremes.lngMax};

    const bounds = new maps.LatLngBounds();
    bounds.extend(sw);
    bounds.extend(ne);

    map.fitBounds(bounds);
    map.setZoom(map.getZoom() + 0.0);
  }

  function showGeoFences() {
    if (displayZones) {
      for (let i = 0; i < geoFencePolygons.length; i++) {
        geoFencePolygons[i].setMap(map);
      }
    } else {
      for (let i = 0; i < geoFencePolygons.length; i++) {
        geoFencePolygons[i].setMap(null);
      }
    }
  }

  /**
   * Displays row bearing lines on the map.
   */
  function showRowBearing() {
    if (displayZones && displayRowBearing && mapZoomLevel > 13) {
      for (let i = 0; i < rowBearings.length; i++) {
        rowBearings[i].setMap(map);
        // Only display in the current view
        // if (map.getBounds().contains(rowBearingsText[i].getPosition())) {
        //   rowBearings[i].setMap(map);
        //   rowBearingsText[i].setMap(map);
        // }
      }
    } else {
      for (let i = 0; i < rowBearings.length; i++) {
        rowBearings[i].setMap(null);
        // rowBearingsText[i].setMap(null);
      }
    }
  }

  function zoomToZone() {
    Object.values(zonesData).forEach((zoneArray) => {
      for (let i = 0; i < zoneArray.length; i++) {
        if (
          zoneArray[i].block_name == zoneZoom ||
          zoneArray[i].field_name == zoneZoom ||
          zoneArray[i].region_name == zoneZoom
        ) {
          const zoneObj = zoneArray[i];

          const latMax = parseFloat(zoneObj.lat_max);
          const latMin = parseFloat(zoneObj.lat_min);
          const lngMax = parseFloat(zoneObj.lng_max);
          const lngMin = parseFloat(zoneObj.lng_min);

          const sw = {lat: latMin, lng: lngMin};
          const ne = {lat: latMax, lng: lngMax};

          const bounds = new maps.LatLngBounds();
          bounds.extend(sw);
          bounds.extend(ne);

          map.fitBounds(bounds);
          map.setZoom(map.getZoom() + 0.0);

          dispatch(updateZoneZoom(''));
        }
      }
    });
  }

  function changeZonesColor() {
    // Update color index
    zoneColorIndex = (zoneColorIndex + 1) % zoneColorsList.length;
    const currPolygons = geoFencePolygons;
    for (let i = 0; i < currPolygons.length; i++) {
      currPolygons[i].setOptions({
        strokeColor: zoneColorsList[zoneColorIndex],
        fillColor: zoneColorsList[zoneColorIndex],
      });
    }
    setGeoFencePolygons(currPolygons);
  }

  function changeZoneLabels() {
    setZoneLabelsIndex((zoneLabelsIndex + 1) % 6);
  }

  return (
    <React.Fragment>
      <GoogleMapReact
        bootstrapURLKeys={{key: 'AIzaSyANXSBQLKbCPDicQsIvWsMvUWaTinztW6Q'}}
        defaultCenter={[0, 0]}
        defaultZoom={5}
        options={mapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({map, maps}) => {
          return handleApiLoaded(map, maps);
        }}
      >
        {displayZones && zoneLabelsIndex < 5 && mapZoomLevel > 13 && zoneLabels}
      </GoogleMapReact>
      {createPortal(
        <React.Fragment>
          <div>
            <button className='btn-lg bg-light mt-2 mr-2' onClick={showAll}>
              <FontAwesomeIcon icon='fas fa-eye' fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2'
              onClick={() => {
                return setDisplayZones(!displayZones);
              }}
            >
              <FontAwesomeIcon icon={displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
            </button>
          </div>
          {displayZones && (
            <React.Fragment>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  onClick={() => {
                    return setDisplayRowBearing(!displayRowBearing);
                  }}
                >
                  <FontAwesomeIcon icon={displayRowBearing ? 'fas fa-compass' : 'far fa-compass'} fixedWidth />
                </button>
              </div>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZoneLabels();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
                </button>
              </div>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZonesColor();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
                </button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>,
        controlButtonDiv
      )}
    </React.Fragment>
  );
}

export {Map};
