import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {createPortal} from 'react-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GoogleMapReact, {convertNwSeToNeSw} from 'google-map-react';
import {DateTime} from 'luxon';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Checkbox from '@mui/material/Checkbox';
import {MarkerClusterer} from '@googlemaps/markerclusterer';

import {
  machineTypeMapping,
  fetchPostAuthSafe,
  polygonArea,
  unitsAreaConversion,
  unitsLengthDisplayConversion,
} from '../../app/utils';
import {setSelectedVehicle, setSelectedTask, setRefreshCabviewDataStream} from './cabviewSlice';
import {drawVehiclePath, drawHazardMarker, drawCanvas, createIconImage} from './Markers';
import {updateDisplayedPlotLines, updateDisplayClusters} from './cabviewSlice';

const machineIconMapping = {
  0: '/img/tractor_green.png',
  1: '/img/passenger-vehicle.png',
  2: '/img/tractor_green.png',
  3: '/img/trailer.png',
  4: '/img/atv.png',
  5: '/img/platform.png',
  6: '/img/construction.png',
};
const machineTypeList = Object.keys(machineTypeMapping).map((str) => {
  return parseInt(str);
});

// Label for adding onto map
const LabelComponent = (props) => {
  return (
    <label
      style={{
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        textAlign: 'center',
        minWidth: '25px',
      }}
    >
      {props.text}
      <br />
      {typeof props.remainingRei != 'undefined' && props.remainingRei > 0 && (
        <span style={{color: '#a30000'}}>Remaining REI: {props.remainingRei}h</span>
      )}
    </label>
  );
};

// Control button div defined here so that it can be populated using jsx and then appended to the map once initialized
const controlButtonDiv = document.createElement('div');
// Global variables
let autoZoomInProgress = false; // Prevents event handlers during non-user triggered map zooming
let map = null; // Reference to google 'map' object
let maps = null; // Reference to google 'maps' object

const zoneColorsList = ['#4daf4a', '#4a4daf', '#af4a99', '#000000', '#ffffff'];

function Map({socket}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehicleData = useSelector((state) => {
    return state.cabview.vehicles;
  });
  const hazardData = useSelector((state) => {
    return state.cabview.hazards;
  });
  const selectedVehicle = useSelector((state) => {
    return state.cabview.selectedVehicle;
  });
  const zonesData = useSelector((state) => {
    return state.cabview.zonesData;
  });
  const geoFenceIntelliBlockDict = useSelector((state) => {
    return state.cabview.geoFenceIntelliBlockDict;
  });
  const reiActiveDict = useSelector((state) => {
    return state.cabview.reiActiveDict;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const taskActive = useSelector((state) => {
    return state.cabview.taskActive;
  });
  const vehiclePlotLinesDisplay = useSelector((state) => {
    return state.cabview.displayedPlotLines;
  });
  const displayClusters = useSelector((state) => {
    return state.cabview.displayClusters;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [mapApiLoaded, setMapApiLoaded] = useState(false);
  const [zoneColorIndex, setZoneColorIndex] = useState(0);

  // Single zone info window so that only one is open at a time
  const [zoneInfoWindow, setZoneInfoWindow] = useState(null);

  // Single vehicle info window so that only one is open at a time
  const [vehInfoWindow, setVehInfoWindow] = useState(null);

  // Note vehicleMarkers is a dict that is indexed/keyed by the vehicle seial number
  const [vehicleMarkers, setVehicleMarkers] = useState({});
  const [hazardMarkers, setHazardMarkers] = useState([]);
  const [mapState, setMapState] = useState({
    locate: false,
    showAll: true,
  });
  const [mapZoomLevel, setMapZoomLevel] = useState(0);
  const [displayZones, setDisplayZones] = useState(true);
  const [zoneLabelsIndex, setZoneLabelsIndex] = useState(0);
  const [geoFencePolygonsByIBlockNum, setGeoFencePolygonsByIBlockNum] = useState({});
  const [zoneLabels, setZoneLabels] = useState([]);
  const [vehicleTypesArray, setVehicleTypesArray] = useState([]);
  const [plotLinesModalOpen, setPlotLinesModalOpen] = useState(false);

  // State to track markers that are selected/deselected to re-draw specific markers without rebuilding all markers
  const [currentSelectedVehicle, setCurrentSelectedVehicle] = useState('');
  const [clusterSelectedVehicle, setClusterSelectedVehicle] = useState('');
  const [markerCluster, setMarkerCluster] = useState(null);
  const [toggleRerenderGeofences, setToggleRerenderGeofences] = useState(true);

  // Set initial plot lines display based on customer setting
  let initPlotLineDisplay = [];
  if (customerSettings.cabview.initPlotLineDisplayOn) {
    initPlotLineDisplay = machineTypeList;
  }

  const zoneInfoEnabled =
    typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;

  useEffect(() => {
    setMapState({
      locate: false,
      showAll: true,
    });
  }, []);

  useEffect(() => {
    // Trigger a map locate or showAll action if the mapState has been changed
    if (map != null && maps != null) {
      maps.event.clearListeners(map, 'click');
      if (zoneInfoWindow != null) {
        map.addListener('click', () => {
          zoneInfoWindow.close();
        });
      }
      if (vehInfoWindow != null) {
        map.addListener('click', () => {
          vehInfoWindow.close();
        });
      }
    }
  }, [zoneInfoWindow, vehInfoWindow]);

  useEffect(() => {
    // Trigger a map locate or showAll action if the mapState has been changed
    if (map != null && maps != null) {
      if (markerCluster != null) {
        markerCluster.renderer = {
          // Custom Render Function to default color of cluster to Red
          render({count, position}, stats, map) {
            const color = '#ffffff';

            // create svg url with fill color
            const svg = window.btoa(`
              <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity=".6" r="90" />
                <circle cx="120" cy="120" opacity=".3" r="110" />
                <circle cx="120" cy="120" opacity=".2" r="120" />
                <circle cx="120" cy="120" opacity=".1" r="150" />
              </svg>`);

            // create marker using svg icon
            return new google.maps.Marker({
              position,
              icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
              },
              label: {
                text: String(count),
                color: 'rgba(0,0,0,0.9)',
                fontSize: '14px',
                fontWeight: 'bold',
              },
              // adjust zIndex to be above other markers
              zIndex: 1000 + count,
            });
          },
        };
        markerCluster.onClusterClick = onClusterHandler;

        const infoWindowSelectButtons = document.querySelectorAll('.iw-btn');
        for (let i = 0; i < infoWindowSelectButtons.length; i++) {
          if (!taskActive) {
            infoWindowSelectButtons[i].addEventListener('click', (e) => {
              const vehicleSN = e.target.getAttribute('data-vehiclesn');
              setClusterSelectedVehicle(vehicleSN);
            });
            infoWindowSelectButtons[i].disabled = false;
          } else {
            infoWindowSelectButtons[i].disabled = true;
          }
        }
      }
    }
  }, [markerCluster, taskActive]);

  useEffect(() => {
    // Updated markers on map if new data or vehicle selection changed
    if (map != null && maps != null) {
      updateMap();
    }

    // Determine vehicle types
    const vehicleTypesArrayHolder = [];
    vehicleData.forEach((vehicleObj) => {
      const machineType = vehicleObj.machineType != undefined ? vehicleObj.machineType : 0;
      if (!vehicleTypesArrayHolder.includes(machineType)) {
        vehicleTypesArrayHolder.push(machineType);
      }
    });
    setVehicleTypesArray(vehicleTypesArrayHolder);
  }, [
    vehicleData,
    hazardData,
    selectedVehicle,
    taskActive,
    vehiclePlotLinesDisplay,
    displayClusters,
    zoneInfoWindow,
    vehInfoWindow,
    mapApiLoaded,
  ]);

  useEffect(() => {
    if (map != null && maps != null) {
      // Trigger a map locate or showAll action if the mapState has been changed
      if (mapState.locate) {
        locateVehicle();
      } else if (mapState.showAll) {
        showAllVehicles();
      }

      if (vehInfoWindow != null) {
        vehInfoWindow.close();
      }
      if (zoneInfoWindow != null) {
        zoneInfoWindow.close();
      }
    }
  }, [mapState]);

  useEffect(() => {
    // Locate vehicle if vehicle selection changed
    if (map != null && maps != null) {
      if (selectedVehicle != '' && vehicleMarkers.hasOwnProperty(selectedVehicle)) {
        setMapState({
          locate: true,
          showAll: false,
        });
        // When selectedVehicle is updated, currentSelectedVehicle would represent the previous selected vehicle
        // Use this state to redraw the markers being deselected and selected, then update the state
        if (currentSelectedVehicle) {
          updateVehicleMarker(currentSelectedVehicle, false);
        }
        updateVehicleMarker(selectedVehicle, true);
        setCurrentSelectedVehicle(selectedVehicle);
      }
    }
  }, [selectedVehicle]);

  useEffect(() => {
    // listen to cluster InfoWindow select vehicle
    if (map != null && maps != null) {
      if (clusterSelectedVehicle != '') {
        selectVehicle(clusterSelectedVehicle);
      }
    }
  }, [clusterSelectedVehicle]);

  useEffect(() => {
    if (map != null && maps != null) {
      plotGeoFences();
    }
  }, [geoFenceIntelliBlockDict, zonesData, reiActiveDict]);

  useEffect(() => {
    if (map != null && maps != null) {
      showGeoFences();
    }
  }, [toggleRerenderGeofences, zoneLabels, displayZones]);

  useEffect(() => {
    if (map != null && maps != null) {
      setZoneLabels(generateZoneLabels());
    }
  }, [zoneLabelsIndex, geoFenceIntelliBlockDict, zonesData, reiActiveDict]);

  // Options for google map appearance and functionality
  const mapOptions = {
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    rotateControl: false,
    tilt: 0,
  };

  // Plot lines display box style and functionality
  const plotLinesDisplayBoxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  function handleToggleRerenderGeofences() {
    // Toggle State to trigger Effects that re-renders geofences, reduce number of geofences displayed;
    setToggleRerenderGeofences((value) => {
      return !value;
    });
  }

  function onClusterHandler(event, cluster, map) {
    // Check if cluster is zoomed (Cluster Does not break at high zoom means markers are overlapped)
    if (map.getZoom() >= 19) {
      // Generate cluster InfoWindow Content
      const clusterContentString = generateClusterInfoWindow(cluster.markers);
      // Open InfoWindow on cluster Location
      if (typeof vehInfoWindow != 'undefined' && vehInfoWindow != null) {
        vehInfoWindow.setPosition(cluster.position);
        vehInfoWindow.setContent(clusterContentString);
        vehInfoWindow.open(map);
      }

      // Add event listener to InfoWindow Buttons since functions cannot be dynamically added in string format.
      setTimeout(() => {
        const infoWindowSelectButtons = document.querySelectorAll('.iw-btn');
        for (let i = 0; i < infoWindowSelectButtons.length; i++) {
          if (!taskActive) {
            infoWindowSelectButtons[i].addEventListener('click', (e) => {
              const vehicleSN = e.target.getAttribute('data-vehiclesn');
              setClusterSelectedVehicle(vehicleSN);
            });
            infoWindowSelectButtons[i].disabled = false;
          } else {
            infoWindowSelectButtons[i].disabled = true;
          }
        }
      }, 200);
    } else {
      // Zoom to break cluster
      autoZoomInProgress = true;
      map.fitBounds(cluster.bounds);
      autoZoomInProgress = false;
    }
  }

  function selectVehicle(vehicleSN) {
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == vehicleSN;
    });

    if (!taskActive) {
      // Auto Select the task if the vehicle was previously doing a task
      if (Object.prototype.hasOwnProperty.call(vehicleObj, 'latestTaskId') && vehicleObj['latestTaskId'] !== null) {
        const taskId = vehicleObj['latestTaskId'];
        dispatch(setSelectedTask(taskId));
        // Emit a socket message to backend
        socket.emit('setSelectedTask', {taskId});
      }
      dispatch(setSelectedVehicle(vehicleSN));
      // Emit a socket message to backend
      socket.emit('setSelectedVehicle', {vehicleSN});
    }
  }

  function updateVehicleMarker(vehicleSN, selected) {
    const vehicleObj = vehicleData.find((vehicle) => {
      return vehicle.serialNumber == vehicleSN;
    });

    if (vehicleObj) {
      drawVehicleMarker(map, maps, vehicleObj, selected, vehicleMarkers);
    }
  }

  const vehicleTypesDropdown = () => {
    let allChecker = true;
    vehicleTypesArray.forEach((type) => {
      if (!vehiclePlotLinesDisplay.includes(type)) {
        allChecker = false;
      }
    });

    let vehicleTypesArrayDisplay = [];
    if (vehicleTypesArray.length > 1) {
      vehicleTypesArrayDisplay = vehicleTypesArray;
    }

    return (
      <div>
        <div style={{textAlign: 'center', fontWeight: 'bold'}}>Toggle Plotlines Display</div>
        {vehicleTypesArrayDisplay.map((type) => {
          return renderVehicleTypeOption(
            type,
            machineIconMapping[type],
            machineTypeMapping[type],
            vehiclePlotLinesDisplay.includes(type)
          );
        })}
        {renderVehicleTypeOption('all', '/img/Logo_Shared.png', 'Show All', allChecker)}
        <div className='mt-4' style={{textAlign: 'center', fontWeight: 'bold'}}>
          Toggle Cluster Display
        </div>
        {renderClusterDisplayToggle()}
      </div>
    );
  };

  async function handleTypeChecked(event, type) {
    let displayedPlotLines;
    if (type == 'all' && event.target.checked) {
      dispatch(updateDisplayedPlotLines(machineTypeList));
      displayedPlotLines = machineTypeList;
    } else if (type == 'all' && !event.target.checked) {
      dispatch(updateDisplayedPlotLines([]));
      displayedPlotLines = [];
    } else if (event.target.checked && !vehiclePlotLinesDisplay.includes(type)) {
      dispatch(updateDisplayedPlotLines([...vehiclePlotLinesDisplay, type]));
      displayedPlotLines = [...vehiclePlotLinesDisplay, type];
    } else if (vehiclePlotLinesDisplay.includes(type)) {
      const index = vehiclePlotLinesDisplay.indexOf(type);
      const newVehiclePlotLinesDisplay = [...vehiclePlotLinesDisplay];
      newVehiclePlotLinesDisplay.splice(index, 1);
      dispatch(updateDisplayedPlotLines(newVehiclePlotLinesDisplay));
      displayedPlotLines = newVehiclePlotLinesDisplay;
    }

    socket.emit('updateDisplayedPlotlines', {displayedPlotLines});
  }

  function renderVehicleTypeOption(type, img, text, check) {
    return (
      <div style={{textAlign: 'center'}} key={type}>
        <Checkbox
          key={type}
          checked={check}
          onChange={async (e) => {
            await handleTypeChecked(e, type);
          }}
        />
        <Box
          component='img'
          sx={{
            height: 25,
            width: 20,
            maxHeight: {xs: 25, md: 20},
            maxWidth: {xs: 20, md: 20},
          }}
          alt='Tractor'
          src={img}
        />
        {text}
      </div>
    );
  }

  function renderClusterDisplayToggle(type) {
    return (
      <div style={{textAlign: 'center'}} key={type}>
        <Checkbox
          checked={displayClusters}
          onChange={async (e) => {
            if (displayClusters) {
              markerCluster.clearMarkers();
            }
            if (zoneInfoWindow != null) {
              zoneInfoWindow.close();
            }
            if (vehInfoWindow != null) {
              vehInfoWindow.close();
            }
            dispatch(updateDisplayClusters(!displayClusters));
            socket.emit('updateDisplayClusters', {displayClusters: !displayClusters});
          }}
        />
        Show Cluster Bubble
      </div>
    );
  }

  async function refreshSocket() {
    // Perform a socket refresh
    const socketRefreshCon = await socket.connect();
    // console.log('Socket Refreshed: ', socketRefreshCon.id, socketRefreshCon.connected);

    // Trigger a cabview data stream refresh
    dispatch(setRefreshCabviewDataStream(true));
  }

  return (
    <React.Fragment>
      <div className='cabview-map d-flex h-100 w-100'>
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyANXSBQLKbCPDicQsIvWsMvUWaTinztW6Q'}}
          defaultCenter={[0, 0]}
          defaultZoom={5}
          options={mapOptions}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({map, maps}) => {
            return handleApiLoaded(map, maps);
          }}
        >
          {displayZones &&
            zoneLabelsIndex < (zoneInfoEnabled ? 5 : 3) &&
            mapZoomLevel > 13 &&
            zoneLabels &&
            zoneLabels.length > 0 &&
            map != null &&
            maps != null &&
            zoneLabels.filter((zoneLabel) => {
              return map.getBounds().contains({lat: zoneLabel.props.lat, lng: zoneLabel.props.lng});
            })}
        </GoogleMapReact>
      </div>

      <Modal
        open={plotLinesModalOpen}
        onClose={() => {
          return setPlotLinesModalOpen(false);
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={plotLinesDisplayBoxStyle}>{vehicleTypesDropdown()}</Box>
      </Modal>
      {createPortal(
        <React.Fragment>
          <div>
            <button
              className='btn-lg bg-light mt-2 mr-2'
              onClick={() => {
                return setMapState({locate: true, showAll: false});
              }}
            >
              <FontAwesomeIcon icon={mapState.locate ? 'fas fa-map-marker' : 'fas fa-map-marker-alt'} fixedWidth />
            </button>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2'
              onClick={() => {
                return setMapState({locate: false, showAll: true});
              }}
            >
              <FontAwesomeIcon icon={mapState.showAll ? 'fas fa-eye' : 'far fa-eye'} fixedWidth />
            </button>
          </div>
          <div>
            <div>
              <button
                className='btn-lg bg-light mr-2'
                onClick={() => {
                  return setPlotLinesModalOpen(true);
                }}
              >
                <FontAwesomeIcon icon='fa-solid fa-road' fixedWidth />
              </button>
            </div>
          </div>
          <div>
            <div>
              <button
                className='btn-lg bg-light mr-2'
                onClick={() => {
                  return refreshSocket();
                }}
              >
                <FontAwesomeIcon icon='fa-solid fa-arrows-rotate' fixedWidth />
              </button>
            </div>
          </div>
          <div>
            <button
              className='btn-lg bg-light mr-2'
              onClick={() => {
                return setDisplayZones(!displayZones);
              }}
            >
              <FontAwesomeIcon icon={displayZones ? 'fas fa-clone' : 'far fa-clone'} fixedWidth />
            </button>
          </div>

          {displayZones && (
            <React.Fragment>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZoneLabels();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-comment-alt'} fixedWidth />
                </button>
              </div>
              <div>
                <button
                  className='btn-lg bg-light mr-2 mt-n1'
                  style={{borderTopLeftRadius: '0px', borderTopRightRadius: '0px'}}
                  onClick={() => {
                    return changeZonesColor();
                  }}
                >
                  <FontAwesomeIcon icon={'fas fa-palette'} fixedWidth />
                </button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>,
        controlButtonDiv
      )}
    </React.Fragment>
  );

  function handleApiLoaded(gmap, gmaps) {
    // Set global 'map' and 'maps' objects to those received from the google api
    map = gmap;
    maps = gmaps;

    // Append custom control buttons to map
    map.controls[maps.ControlPosition.TOP_RIGHT].push(controlButtonDiv);
    // Event listeners for clearing map states
    map.addListener('dragstart', function () {
      if (!autoZoomInProgress && (mapState.locate !== false || mapState.showAll !== false)) {
        setMapState({locate: false, showAll: false});
      }
    });
    map.addListener('zoom_changed', function () {
      if (!autoZoomInProgress && (mapState.locate !== false || mapState.showAll !== false)) {
        setMapState({locate: false, showAll: false});
      }
      setMapZoomLevel(map.getZoom());
    });
    map.addListener('idle', function () {
      // Function to toggle state that Trigger Effects
      handleToggleRerenderGeofences();
    });

    // Create infowindow object
    if (typeof vehInfoWindow == 'undefined' || vehInfoWindow == null) {
      const vehInfoWindowTemp = new maps.InfoWindow();
      setVehInfoWindow(vehInfoWindowTemp);
    }

    if (typeof zoneInfoWindow == 'undefined' || zoneInfoWindow == null) {
      const zoneInfoWindowTemp = new maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWindowTemp);
    }

    let markerClusterRef = markerCluster;
    if (!markerClusterRef) {
      const newMarkerCluster = new MarkerClusterer({
        map: map,
        markers: [],
        algorithmOptions: {
          maxZoom: 20,
        },
      });
      markerClusterRef = newMarkerCluster;
      setMarkerCluster(newMarkerCluster);
    } else {
      markerClusterRef.setMap(map);
    }

    // Re-asssign the vehicle marker to new map when map re-init
    const previousVehicleMarkerKeys = Object.keys(vehicleMarkers);
    for (let i = 0; i < previousVehicleMarkerKeys.length; i++) {
      if (vehicleMarkers[previousVehicleMarkerKeys[i]].marker) {
        vehicleMarkers[previousVehicleMarkerKeys[i]].marker.setMap(map);
      }
    }
    // Update map with markers once initialized
    setMapZoomLevel(map.getZoom());
    plotGeoFences();
    setMapApiLoaded(true);
  }

  function updateMap() {
    if (typeof vehInfoWindow == 'undefined' || vehInfoWindow == null) {
      const vehInfoWindowTemp = new maps.InfoWindow();
      setVehInfoWindow(vehInfoWindowTemp);
    }

    if (typeof zoneInfoWindow == 'undefined' || zoneInfoWindow == null) {
      const zoneInfoWindowTemp = new maps.InfoWindow();
      setZoneInfoWindow(zoneInfoWindowTemp);
    }

    // Remove existing hazard markers from map4
    const currHazMarkers = hazardMarkers;
    for (let i = 0; i < currHazMarkers.length; i++) {
      currHazMarkers[i].setMap(null);
    }
    // Add new hazard markers to map
    const newHazMarker = [];
    for (let i = 0; i < hazardData.length; i++) {
      const hazard = hazardData[i];
      const marker = drawHazardMarker(map, maps, hazard);
      newHazMarker.push(marker);
    }
    setHazardMarkers(newHazMarker);

    // Iterate through all vehicle data
    const currVehMarkers = vehicleMarkers;

    // Ensure Marker Cluster is initialized and exist
    let markerClusterRef = markerCluster;
    if (!markerClusterRef) {
      const newMarkerCluster = new MarkerClusterer({
        map: map,
        markers: [],
        algorithmOptions: {
          maxZoom: 20,
        },
      });
      markerClusterRef = newMarkerCluster;
      setMarkerCluster(newMarkerCluster);
    }

    // Clear markers from clusters
    markerClusterRef.clearMarkers();

    for (let i = 0; i < vehicleData.length; i++) {
      if (
        vehicleData[i].gpsCoords == undefined ||
        vehicleData[i].gpsCoords.length <= 0 ||
        vehicleData[i]?.cabviewActive === false
      ) {
        continue;
      }
      const vehicle = vehicleData[i];

      // Determine if vehicle is the selected vehicle in cabview
      const selected = selectedVehicle == vehicle.serialNumber;

      // Add new OR update vehicle markers
      const marker = drawVehicleMarker(map, maps, vehicle, selected, currVehMarkers);

      // Add new OR update vehicle pathLine
      // Check if vehicle type in (array of allowed vehicle types)
      const vehicleTypeShowPathline =
        vehiclePlotLinesDisplay.includes(vehicle.machineType) ||
        (vehicle.machineType == undefined && vehiclePlotLinesDisplay.includes(0));
      const pathLine = drawVehiclePath(
        map,
        maps,
        vehicle,
        selected,
        taskActive,
        vehicleTypeShowPathline,
        currVehMarkers
      );

      // Add to clusters if enabled
      if (displayClusters) {
        markerClusterRef.addMarker(marker);
      } else {
        marker.setMap(map);
      }

      // Add to vehicle marker dict
      currVehMarkers[vehicle.serialNumber] = {
        marker,
        pathLine,
      };
    }

    setVehicleMarkers(currVehMarkers);

    // Adjust map after marker update based on current map state
    if (mapState.locate) {
      locateVehicle();
    } else if (mapState.showAll) {
      showAllVehicles();
    }
  }

  function plotGeoFences() {
    // SHOULD REALLY COMBINE ALL THE MAP COMPONENTS INTO ONE AND CLEAN UP
    // Check if blocks within zoneData
    if (
      !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
      Object.keys(geoFenceIntelliBlockDict).length < 1
    ) {
      return;
    }

    const ploygonArrayByiBlockNum = geoFencePolygonsByIBlockNum;
    // Iterate through blocks
    for (let i = 0; i < zonesData.blocks.length; i++) {
      // Find area of the block
      let blockArea = 0;
      const blockIntelliBlockNums = zonesData.blocks[i].intelliblock_nums;
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

        const geofenceRowPass =
          customerSettings.general.rowPassStatisticsEnabled &&
          geoFence.properties.rowPassEnabled &&
          geoFence.properties.trustRowBearing &&
          geoFence.properties.rowPassAnalysisAvailable &&
          geoFence.properties.rowPassEstimatedArea;
        if (geofenceRowPass) {
          blockArea += geoFence.properties.rowPassEstimatedArea;
        } else {
          blockArea += geoFence.properties.area_meters2;
        }
      }

      // Override block area if manually inputted area exists
      if (
        Object.prototype.hasOwnProperty.call(zonesData.blocks[i], 'manual_area_meters2') &&
        zonesData.blocks[i].manual_area_meters2 !== 0
      ) {
        blockArea = zonesData.blocks[i].manual_area_meters2;
      }

      // Iterate through each multipolygon for plotting
      for (let j = 0; j < blockIntelliBlockNums.length; j++) {
        if (!Object.prototype.hasOwnProperty.call(geoFenceIntelliBlockDict, blockIntelliBlockNums[j])) {
          continue;
        }

        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

        let currentIblockPolygons = [];
        if (
          Object.prototype.hasOwnProperty.call(ploygonArrayByiBlockNum, blockIntelliBlockNums[j]) &&
          ploygonArrayByiBlockNum[blockIntelliBlockNums[j]].length == geoFence.geometry.coordinates.length
        ) {
          // use previously drawn polygon if number of polygons matches iblock geoFence's definition
          currentIblockPolygons = ploygonArrayByiBlockNum[blockIntelliBlockNums[j]];
        } else {
          // Iterate through each polygon in the multipolygon
          for (let k = 0; k < geoFence.geometry.coordinates.length; k++) {
            const latLngList = [];
            let maxMinPoints = [];
            let maxLatPoint;
            let maxLngPoint;
            let minLatPoint;
            let minLngPoint;
            // Iterate through each coordinate set in the polygon.
            // Sets after the first one correspond to holes inside the polygon
            let outerClockwise = false;
            for (let n = 0; n < geoFence.geometry.coordinates[k].length; n++) {
              const coordinates = geoFence.geometry.coordinates[k][n];
              const clockwise = polygonArea(coordinates) > 0;

              let latLngMap = coordinates.map((coordPair) => {
                const tempPoint = {lat: coordPair[1], lng: coordPair[0]};
                if (!maxLatPoint || tempPoint.lat > maxLatPoint.lat) {
                  maxLatPoint = tempPoint;
                }
                if (!maxLngPoint || tempPoint.lng > maxLngPoint.lng) {
                  maxLngPoint = tempPoint;
                }
                if (!minLatPoint || tempPoint.lat < minLatPoint.lat) {
                  minLatPoint = tempPoint;
                }
                if (!minLngPoint || tempPoint.lng < minLngPoint.lng) {
                  minLngPoint = tempPoint;
                }
                return tempPoint;
              });

              if (n == 0) {
                outerClockwise = clockwise;
              } else if ((outerClockwise && clockwise) || (!outerClockwise && !clockwise)) {
                latLngMap = latLngMap.reverse();
              }

              latLngList.push(latLngMap);
            }

            if (maxLatPoint && maxLngPoint && minLatPoint && minLngPoint) {
              maxMinPoints = [maxLatPoint, maxLngPoint, minLatPoint, minLngPoint];
            }

            let rowSpacingWithUnit = 'N/A';
            if (geoFence.properties.row_spacing_meters > 0) {
              rowSpacingWithUnit =
                unitsLengthSystem == 'imperial'
                  ? `${unitsLengthDisplayConversion(geoFence.properties.row_spacing_meters, 'ft').toFixed(2)} ft`
                  : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
            }

            // Convert acreage area to desired units
            let unitsArea = 'ac';
            if (unitsAreaSystem == 'hectare') {
              unitsArea = 'ha';
            }

            const convertedAcreage = unitsAreaConversion(blockArea, unitsArea, 'meters2');
            const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;

            // Create polygon object
            const polygon = new maps.Polygon({
              paths: latLngList,
              strokeColor: zoneColorsList[zoneColorIndex],
              strokeWeight: 0.5,
              strokePosition: google.maps.StrokePosition.INSIDE,
              fillColor: zoneColorsList[zoneColorIndex],
              fillOpacity: 0.3,
              zIndex: -1,
              reiActive: false,
              maxMinPoints: maxMinPoints,
              block_name: geoFence.properties.block_name,
              field_name: geoFence.properties.field_name,
              region_name: geoFence.properties.region_name,
              rowSpacingWithUnit: rowSpacingWithUnit,
              acreageWithUnit: acreageWithUnit,
            });

            polygon.setMap(null);
            currentIblockPolygons.push(polygon);
          }
        }

        // Determine if REI warning should show
        let reiWarningShow = false;
        let remRei = 0;
        if (Object.prototype.hasOwnProperty.call(reiActiveDict, zonesData.blocks[i].block_id)) {
          const reiObj = reiActiveDict[zonesData.blocks[i].block_id];
          const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
          remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

          // Final check to ensure rei interval is valid
          if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
            reiWarningShow = true;
            remRei = Math.ceil(remRei);
          }
        }
        let strokeColor = zoneColorsList[zoneColorIndex];
        let strokeWeight = 0.5;
        let fillColor = zoneColorsList[zoneColorIndex];
        let reiActive = false;
        if (reiWarningShow) {
          strokeColor = '#ff0000';
          strokeWeight = 6;
          fillColor = '#af4a4d';
          reiActive = true;
        }

        currentIblockPolygons.forEach((iblockPolygon) => {
          // Update polygon object
          iblockPolygon.setOptions({
            strokeColor: strokeColor,
            strokeWeight: strokeWeight,
            fillColor: fillColor,
          });
          iblockPolygon.reiActive = reiActive;

          // Add OR Update Polygon info window
          if (iblockPolygon != undefined) {
            let infoWindowContent = `<div><b>${iblockPolygon.block_name}</b></div>`;

            // Display remaining rei
            if (reiWarningShow) {
              infoWindowContent +=
                `<div style="font-size:12px;color:red"><b>DO NOT ENTER</b></div>` +
                `<div style="font-size:10px;color:red"><b>Remaining REI:</b> ${remRei} hours</div>`;
            }

            // Display field and region
            infoWindowContent +=
              `<div style="font-size:10px"><b>Field:</b> ${iblockPolygon.field_name}</div>` +
              `<div style="font-size:10px"><b>Region:</b> ${iblockPolygon.region_name}</div>`;

            // Display zone info
            if (zoneInfoEnabled) {
              const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
              infoWindowContent +=
                `<div style="font-size:10px"><b>Row Spacing:</b> ${iblockPolygon.rowSpacingWithUnit}</div>` +
                `<div style="font-size:10px"><b>${areaLabel}:</b> ${iblockPolygon.acreageWithUnit}</div>`;
            }

            maps.event.clearListeners(iblockPolygon, 'click');
            iblockPolygon.addListener('click', (event) => {
              zoneInfoWindow.setContent(infoWindowContent);
              zoneInfoWindow.setPosition(event.latLng);
              zoneInfoWindow.open({
                map,
                shouldFocus: false,
              });
            });
          }
        });

        ploygonArrayByiBlockNum[blockIntelliBlockNums[j]] = currentIblockPolygons;
      }
    }

    setGeoFencePolygonsByIBlockNum(ploygonArrayByiBlockNum);
  }

  function generateZoneLabels() {
    let allZoneLabels = [];
    if (maps != null) {
      // Check if blocks within zoneData
      if (
        !Object.prototype.hasOwnProperty.call(zonesData, 'blocks') ||
        Object.keys(geoFenceIntelliBlockDict).length < 1
      ) {
        return;
      }

      allZoneLabels = zonesData.blocks.map((block, index) => {
        // Find area of the block
        let blockArea = 0;
        const blockIntelliBlockNums = block.intelliblock_nums;
        for (let j = 0; j < blockIntelliBlockNums.length; j++) {
          const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[j]];

          const geofenceRowPass =
            customerSettings.general.rowPassStatisticsEnabled &&
            geoFence.properties.rowPassEnabled &&
            geoFence.properties.trustRowBearing &&
            geoFence.properties.rowPassAnalysisAvailable &&
            geoFence.properties.rowPassEstimatedArea;
          if (geofenceRowPass) {
            blockArea += geoFence.properties.rowPassEstimatedArea;
          } else {
            blockArea += geoFence.properties.area_meters2;
          }
        }

        // Override block area if manually inputted area exists
        if (Object.prototype.hasOwnProperty.call(block, 'manual_area_meters2') && block.manual_area_meters2 !== 0) {
          blockArea = block.manual_area_meters2;
        }

        // Determine if REI warning should show
        let remRei = '';
        if (Object.prototype.hasOwnProperty.call(reiActiveDict, block.block_id)) {
          const reiObj = reiActiveDict[block.block_id];
          const reiEndTime = DateTime.fromISO(reiObj.exitTime['@ts']).plus({hours: reiObj.reiHours});
          remRei = reiEndTime.diff(DateTime.now(), 'hours').toObject()?.hours;

          // Final check to ensure rei interval is valid
          if (remRei && remRei > 0 && remRei <= reiObj.reiHours) {
            remRei = Math.ceil(remRei);
          }
        }

        // Get one geoFence of block
        const geoFence = geoFenceIntelliBlockDict[blockIntelliBlockNums[0]];

        let zoneName = geoFence.properties.block_name;
        if (zoneLabelsIndex == 1) {
          zoneName = geoFence.properties.field_name;
        } else if (zoneLabelsIndex == 2) {
          zoneName = geoFence.properties.region_name;
        } else if (zoneLabelsIndex == 3 && zoneInfoEnabled) {
          let rowSpacingWithUnit = 'N/A';
          if (geoFence.properties.row_spacing_meters > 0) {
            rowSpacingWithUnit =
              unitsLengthSystem == 'imperial'
                ? `${unitsLengthDisplayConversion(geoFence.properties.row_spacing_meters, 'ft').toFixed(2)} ft`
                : `${geoFence.properties.row_spacing_meters.toFixed(2)} m`;
          }
          zoneName = `Row spacing: ${rowSpacingWithUnit}`;
        } else if (zoneLabelsIndex == 4 && zoneInfoEnabled) {
          // Convert acreage area to desired units

          let unitsArea = 'ac';
          if (unitsAreaSystem == 'hectare') {
            unitsArea = 'ha';
          }

          const convertedAcreage = unitsAreaConversion(blockArea, unitsArea, 'meters2');
          const acreageWithUnit = `${convertedAcreage.toFixed(2)} ${unitsArea}`;
          const areaLabel = unitsAreaSystem == 'hectare' ? 'Hectares' : 'Acreage';
          zoneName = `${areaLabel}: ${acreageWithUnit}`;
        }

        const latCenter = (block.lat_max + block.lat_min) / 2;
        const lngCenter = (block.lng_max + block.lng_min) / 2;
        return <LabelComponent key={index} lat={latCenter} lng={lngCenter} text={zoneName} remainingRei={remRei} />;
      });
    }
    return allZoneLabels;
  }

  function locateVehicle() {
    const minVehicleZoomLevel = 18;
    // Zoom into the selected vehicle
    if (Object.keys(vehicleMarkers).length > 0 && selectedVehicle && vehicleMarkers.hasOwnProperty(selectedVehicle)) {
      const selectedMarker = vehicleMarkers[selectedVehicle].marker;
      // autoZoomInProgress prevents map event listeners from triggering
      autoZoomInProgress = true;
      if (map.getZoom() < minVehicleZoomLevel) {
        map.setZoom(minVehicleZoomLevel);
      }
      map.panTo(selectedMarker.position);
      autoZoomInProgress = false;
    }
  }

  function showAllVehicles() {
    // Fit all markers on map into view
    if (Object.keys(vehicleMarkers).length > 0) {
      const bounds = new maps.LatLngBounds();
      for (const vehicleMarker in vehicleMarkers) {
        if (vehicleMarkers.hasOwnProperty(vehicleMarker)) {
          bounds.extend(vehicleMarkers[vehicleMarker].marker.position);
        }
      }
      // autoZoomInProgress prevents map event listeners from triggering
      autoZoomInProgress = true;
      map.fitBounds(bounds);
      autoZoomInProgress = false;
    }
  }

  function showGeoFences() {
    const geoFencesIBlockNums = Object.keys(geoFencePolygonsByIBlockNum);
    const mapCenterLat = map.getCenter().lat();
    const mapCenterLng = map.getCenter().lng();
    for (let i = 0; i < geoFencesIBlockNums.length; i++) {
      const iblockPolygons = geoFencePolygonsByIBlockNum[geoFencesIBlockNums[i]];
      iblockPolygons.forEach((polygon) => {
        if (!displayZones) {
          polygon.setMap(null);
          return;
        }

        // Check if geoFence is in View and show geoFence if it is.
        const centerWithinPolygon =
          google.maps.geometry?.poly.containsLocation({lat: mapCenterLat, lng: mapCenterLng}, polygon) || false;
        const maxMinPoints = polygon.maxMinPoints;
        if (
          !maxMinPoints ||
          mapZoomLevel < 10 ||
          !(
            maxMinPoints.some((point) => {
              return map.getBounds().contains(point);
            }) || centerWithinPolygon
          )
        ) {
          polygon.setMap(null);
        } else {
          polygon.setMap(map);
        }
      });
    }
  }

  function changeZonesColor() {
    // Update color index
    const newZoneColorIndex = (zoneColorIndex + 1) % zoneColorsList.length;
    const geoFencesIBlockNums = Object.keys(geoFencePolygonsByIBlockNum);
    for (let i = 0; i < geoFencesIBlockNums.length; i++) {
      const iblockPolygons = geoFencePolygonsByIBlockNum[geoFencesIBlockNums[i]];
      iblockPolygons.forEach((polygon) => {
        if (polygon.reiActive) {
          return;
        }
        polygon.setOptions({
          strokeColor: zoneColorsList[newZoneColorIndex],
          fillColor: zoneColorsList[newZoneColorIndex],
        });
      });
    }
    setZoneColorIndex(newZoneColorIndex);
  }

  function changeZoneLabels() {
    const zoneInfoEnabled =
      typeof userSettings.general.zoneInfoEnabled != 'undefined' && userSettings.general.zoneInfoEnabled;
    const labelCycleLength = zoneInfoEnabled ? 6 : 4;
    setZoneLabelsIndex((zoneLabelsIndex + 1) % labelCycleLength);
  }

  function drawVehicleMarker(map, maps, vehicle, selected, vehicleMarkers = {}) {
    const canvas = drawCanvas(vehicle, selected, taskActive);
    const vehImg = createIconImage(maps, canvas);

    let marker;
    if (vehicleMarkers[vehicle.serialNumber]?.marker) {
      marker = reCreateVehicleMarker(
        map,
        maps,
        vehicle,
        vehImg,
        selected,
        vehicleMarkers[vehicle.serialNumber]?.marker
      );
      // marker = vehicleMarkers[vehicle.serialNumber]?.marker;
    } else {
      marker = createVehicleMarker(map, maps, vehicle, vehImg, selected);
    }

    return marker;
  }

  function createVehicleMarker(map, maps, vehicle, vehImg, selected) {
    // Add marker on top of others if vehicle is selected
    let zIndex = 1;
    if (selected) {
      zIndex = 2;
    }
    // Add new marker
    const marker = new maps.Marker({
      position: {
        lat: vehicle.gpsCoords[vehicle.gpsCoords.length - 1].latitude,
        lng: vehicle.gpsCoords[vehicle.gpsCoords.length - 1].longitude,
      },
      icon: vehImg,
      zIndex: zIndex,
      map,
    });

    // Create infowindow for when marker is clicked on
    const vehicleSpeed =
      unitsLengthSystem == 'imperial'
        ? `${unitsLengthDisplayConversion(vehicle.speed, 'mph').toFixed(1)} mph`
        : `${vehicle.speed.toFixed(1)} km/h`;
    const contentDate = DateTime.fromISO(vehicle.gpsCoords[vehicle.gpsCoords.length - 1].dateTime);
    const contentDateString = contentDate
      .setZone(customerSettings.general.timeZone)
      .toLocaleString(DateTime.DATETIME_SHORT);
    const contentString =
      `<div><b>${vehicle.name}</b></div>` +
      `<div style="font-size:10px"><b>Speed:</b> ${vehicleSpeed}</div>` +
      `<div style="font-size:10px"><b>Task:</b> ${vehicle.latestTaskName}</div>` +
      `<div style="font-size:10px"><b>Last Communicated:</b> ${contentDateString}</div>`;

    marker['vehicleData'] = vehicle;
    // This listener checks if markers gets clicked
    maps.event.clearListeners(marker, 'click');
    marker.addListener('click', () => {
      if (!taskActive) {
        selectVehicle(vehicle.serialNumber);
      }

      if (typeof vehInfoWindow != 'undefined' && vehInfoWindow != null) {
        vehInfoWindow.setContent(contentString);
        vehInfoWindow.open(map, marker);
      }
    });

    return marker;
  }

  function reCreateVehicleMarker(map, maps, vehicle, vehImg, selected, marker) {
    // Add marker on top of others if vehicle is selected
    let zIndex = 1;
    if (selected) {
      zIndex = 2;
    }
    const currentMarkerLat = marker.getPosition().lat();
    const currentMarkerLng = marker.getPosition().lng();
    const latestGpsPoint = vehicle.gpsCoords[vehicle.gpsCoords.length - 1];
    if (!(latestGpsPoint.latitude == currentMarkerLat && latestGpsPoint.longitude == currentMarkerLng)) {
      // if gps position is updated
      marker.setPosition({
        lat: latestGpsPoint.latitude,
        lng: latestGpsPoint.longitude,
      });
    }
    marker.setIcon(vehImg);
    marker.setZIndex(zIndex);
    // Create infowindow for when marker is clicked on
    const vehicleSpeed =
      unitsLengthSystem == 'imperial'
        ? `${unitsLengthDisplayConversion(vehicle.speed, 'mph').toFixed(1)} mph`
        : `${vehicle.speed.toFixed(1)} km/h`;
    const contentDate = DateTime.fromISO(vehicle.gpsCoords[vehicle.gpsCoords.length - 1].dateTime);
    const contentDateString = contentDate
      .setZone(customerSettings.general.timeZone)
      .toLocaleString(DateTime.DATETIME_SHORT);
    const contentString =
      `<div><b>${vehicle.name}</b></div>` +
      `<div style="font-size:10px"><b>Speed:</b> ${vehicleSpeed}</div>` +
      `<div style="font-size:10px"><b>Task:</b> ${vehicle.latestTaskName}</div>` +
      `<div style="font-size:10px"><b>Last Communicated:</b> ${contentDateString}</div>`;

    marker['vehicleData'] = vehicle;
    // This listener checks if markers gets clicked
    maps.event.clearListeners(marker, 'click');

    marker.addListener('click', () => {
      if (!taskActive) {
        selectVehicle(vehicle.serialNumber);
      }

      if (typeof vehInfoWindow != 'undefined' && vehInfoWindow != null) {
        vehInfoWindow.setContent(contentString);
        vehInfoWindow.open(map, marker);
      }
    });

    return marker;
  }

  function generateClusterInfoWindow(markers) {
    let clusterContentString = '';
    for (let i = 0; i < markers.length; i++) {
      const vehicle = markers[i].vehicleData;

      // Create infowindow for when marker is clicked on
      const vehicleSpeed =
        unitsLengthSystem == 'imperial'
          ? `${unitsLengthDisplayConversion(vehicle.speed, 'mph').toFixed(1)} mph`
          : `${vehicle.speed.toFixed(1)} km/h`;
      const contentDate = DateTime.fromISO(vehicle.gpsCoords[vehicle.gpsCoords.length - 1].dateTime);
      const contentDateString = contentDate
        .setZone(customerSettings.general.timeZone)
        .toLocaleString(DateTime.DATETIME_SHORT);

      const contentString =
        `<div><br/><b>${vehicle.name}</b></div>` +
        `<div style="font-size:10px"><b>Speed:</b> ${vehicleSpeed}</div>` +
        `<div style="font-size:10px"><b>Task:</b> ${vehicle.latestTaskName}</div>` +
        `<div style="font-size:10px"><b>Last Communicated:</b> ${contentDateString}</div>` +
        `<button class="btn btn-sm btn-success iw-btn" data-vehiclesn="${vehicle.serialNumber}">Select Vehicle</button>` +
        `<div><br/></div>`;
      clusterContentString += contentString;
    }
    return clusterContentString;
  }
}

export {Map};
