import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, deepCopy, unionLabelsAndDataVehicles} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genIdlePercentByMachine, genIdlePercentByBucket} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress, FormControl, InputLabel, Grid, Box} from '@mui/material';
import ChartJS from 'chart.js/auto';
import {setCompareByDatesCache, setCompareByToggle, setFilterOptions} from '../dashboardSlice';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiIdling(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const installTypes = useSelector((state) => {
    return state.dashboard.installTypes;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const activeDevices = useSelector((state) => {
    return state.dashboard.activeDevices;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });

  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [selectedSpecialView, setSelectedSpecialView] = useState('Vehicle');
  const [displayedAxis, setDisplayedAxis] = useState('Idle %');
  const [idleData, setIdleData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [idleTarget, setIdleTarget] = useState(null);
  const [idleFilters, setIdleFilters] = useState({
    idleVehicleSNs: [],
    idleVehicleType: [],
    idleVehicleCount: '25',
    idleVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    vehicleSNDict,
    idleFilters,
    activeDevices,
    displayedAxis,
    selectedSpecialView,
    compareByToggle,
    compareByOperationsData,
  ]);

  useEffect(() => {
    if (compareByToggle) {
      setSelectedSpecialView('Vehicle');
    }
  }, [compareByToggle]);

  useEffect(() => {
    const filterOptionsTemp = deepCopy(filterOptions);
    if (selectedSpecialView == 'Vehicle') {
      filterOptionsTemp.vehicles.efficiencyVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    } else {
      filterOptionsTemp.vehicles.efficiencyVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Chronological', text: 'Chronological'},
      ];
    }
    dispatch(setFilterOptions(filterOptionsTemp));
  }, [selectedSpecialView]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  useEffect(() => {
    if (props?.pageFilters) {
      setIdleFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.selectedSpecialView) {
      setSelectedSpecialView(props.pageStates?.selectedSpecialView);
    }
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  function generateData() {
    // Set target
    let idleTargetTemp = customerSettings?.targets?.totalIdlePercent;

    // If we only have 1 type selected display that vehicles target
    if (idleFilters.idleVehicleType.length == 1) {
      idleTargetTemp =
        customerSettings.targets.typeIdleTimePercent[idleFilters.idleVehicleType[0]] != 0
          ? customerSettings.targets.typeIdleTimePercent[idleFilters.idleVehicleType[0]]
          : undefined;
    }
    // If more than 1 type is selected don't display any target
    else if (idleFilters.idleVehicleType.length > 1) {
      idleTargetTemp = undefined;
    }
    // If there is a specific vehicle selected show that vehicle's target
    if (idleFilters.idleVehicleSNs.length == 1) {
      idleTargetTemp =
        typeof vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget != undefined &&
        vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget != 0
          ? vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget
          : undefined;
    }
    // If there is more than 1 specific vehicle selected and it isn't just 1 singular type we won't display a target
    // But if it's just 1 singular type then we would be displaying the target for that type
    else if (idleFilters.idleVehicleSNs.length > 1 && idleFilters.idleVehicleType.length != 1) {
      idleTargetTemp = undefined;
    }
    setIdleTarget(idleTargetTemp);
    if (props.hasOwnProperty('pageStates') && props.pageStates != undefined) {
      if (props.pageStates.hasOwnProperty('pageTargets')) {
        setIdleTarget(props.pageStates.pageTargets);
      }
      if (props.pageStates.hasOwnProperty('displayedAxis')) {
        setDisplayedAxis(props.pageStates.displayedAxis);
      }
    }

    if (displayedAxis != 'Idle %') {
      setIdleTarget(null);
    }

    // Generate data
    let installTypesInput = installTypes;
    if (
      typeof customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay !== 'undefined' &&
      customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay === false
    ) {
      installTypesInput = {};
    }

    let vehiclesObjectInput = operationsData.vehiclesObject;
    let compareByVehiclesObjectInput;
    if (compareByToggle && compareByOperationsData != null) {
      const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
        operationsData.vehiclesObject,
        compareByOperationsData.vehiclesObject,
        ['type', 'vehicleName']
      );
      compareByVehiclesObjectInput = newCompareByOpsVehiclesObject;
      vehiclesObjectInput = newOpsVehiclesObject;
    }

    let idleDataForDisplay = genIdlePercentByMachine(
      vehiclesObjectInput,
      displayedAxis,
      idleFilters,
      vehicleSNDict,
      installTypesInput,
      activeDevices,
      customerSettings.dashboard.kpiIdleHoverDetailsEnabled,
      vehiclesObjectInput
    );
    let compareByIdleDataForDisplay;
    if (compareByToggle && compareByOperationsData != null) {
      compareByIdleDataForDisplay = genIdlePercentByMachine(
        compareByVehiclesObjectInput,
        displayedAxis,
        idleFilters,
        vehicleSNDict,
        installTypesInput,
        activeDevices,
        customerSettings.dashboard.kpiIdleHoverDetailsEnabled,
        vehiclesObjectInput,
        true
      );
      idleDataForDisplay.datasets = [...compareByIdleDataForDisplay.datasets, ...idleDataForDisplay.datasets];
      idleDataForDisplay.datasets = idleDataForDisplay.datasets.sort((a, b) => {
        return a.order - b.order;
      });
    }

    let temporalOpsData;
    let idlePercentTemporal;
    if (timeAxes.includes(selectedSpecialView)) {
      if (selectedSpecialView == 'Days') {
        temporalOpsData = operationsData.opsByDay;
      } else if (selectedSpecialView == 'Weeks') {
        temporalOpsData = operationsData.opsByWeek;
      } else if (selectedSpecialView == 'Months') {
        temporalOpsData = operationsData.opsByMonth;
      } else if (selectedSpecialView == 'Years') {
        temporalOpsData = operationsData.opsByYear;
      }
      idlePercentTemporal = genIdlePercentByBucket(
        temporalOpsData,
        displayedAxis,
        idleFilters,
        vehicleSNDict,
        installTypesInput,
        activeDevices,
        customerSettings.dashboard.kpiIdleHoverDetailsEnabled
      );
      idleDataForDisplay = idlePercentTemporal;
    }

    if (Object.keys(operationsData.vehiclesObject).length > 0) {
      // Set data
      setIdleData(idleDataForDisplay);
      // Set datasets
      const datasetsIncluded = [];
      idleDataForDisplay.datasets.forEach((dataset) => {
        if (displayedAxis == dataset.id || ['Missing Idle Data', 'Target'].includes(dataset.id)) {
          datasetsIncluded.push(dataset);
        }
      });
      setDatasets(datasetsIncluded);
    } else {
      setIdleData(null);
      setDatasets(null);
    }
  }

  function handleVehiclesFilters(value, selectName) {
    setIdleFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function idlingGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{
            labels: idleData.labels,
            datasets: datasets,
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: idleTarget,
                    endValue: idleTarget,
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 2,
                    display: typeof idleTarget != 'undefined' && idleTarget != 0,
                  },
                },
              },
              title: {
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
                text: `Operational Efficiency - ${displayedAxis} by Machine`,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || idleTarget;
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: displayedAxis,
                  display: !!props?.graphOnly,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
              x: {
                ticks: {
                  autoSkip: idleData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 0,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: 'Machine',
                  display: !!props?.graphOnly,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
          plugins={[
            {
              id: 'customPlugin',
              beforeDraw: function (chart) {
                chart.config.data.datasets.forEach((dataset, i) => {
                  if (dataset.legendBackgroundColor && chart.legend.legendItems[i]) {
                    chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
                    chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor
                      ? dataset.legendBorderColor
                      : dataset.legendBackgroundColor;
                  }
                });
              },
            },
          ]}
        />
      </div>
    );
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });
      combined = [
        ...ogVehicles,
        ...Object.keys(compareByOperationsData.vehiclesObject)
          .filter((key) => {
            return !mainSetVehicleSN.includes(key);
          })
          .map((opt) => {
            return {'text': vehicleSNDict[opt].name, 'value': opt};
          }),
      ];
      combined = [...combined].sort((optA, optB) => {
        return optA.text.localeCompare(optB.text);
      });
    }
    return combined;
  }

  function filterControlComponents() {
    return [
      /* Vehicle Name Filter */
      <IntelliSearchSelect
        label='Vehicles'
        allNoneEnabled
        id='dashboard-vehicles-efficiencyVehicleSNs-filter'
        key='dashboard-vehicles-efficiencyVehicleSNs-filter'
        name='idleVehicleSNs'
        multiple={true}
        search={true}
        value={idleFilters.idleVehicleSNs}
        options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'idleVehicleSNs');
        }}
        placeholder='Filter for Vehicles'
      />,
      /* Vehicle Type Filter */
      <IntelliSearchSelect
        allNoneEnabled
        label='Vehicle Type'
        id='dashboard-vehicles-efficiencyVehicleType-filter'
        key='dashboard-vehicles-efficiencyVehicleType-filter'
        name='idleVehicleType'
        multiple={true}
        search={true}
        value={idleFilters.idleVehicleType}
        options={filterOptions.vehicles.vehicleType.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'idleVehicleType');
        }}
        placeholder='Filter for Vehicle Type'
      />,
    ];
  }

  function displayControlComponents() {
    return [
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-vehicles-efficiencyVehicleSort-filter'
        key='dashboard-vehicles-efficiencyVehicleSort-filter'
        name='idleVehicleSort'
        multiple={false}
        value={idleFilters.idleVehicleSort}
        options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'idleVehicleSort');
        }}
        placeholder='Sorting method'
      />,
      /* Vehicle Count Filter */
      <IntelliSearchSelect
        label={`${selectedSpecialView || 'Vehicle'} Count`}
        id='dashboard-vehicles-efficiencyVehicleCount-filter'
        key='dashboard-vehicles-efficiencyVehicleCount-filter'
        name='idleVehicleCount'
        multiple={false}
        value={idleFilters.idleVehicleCount}
        options={filterOptions.vehicles.vehicleCount.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'idleVehicleCount');
        }}
        placeholder={`Show  All Vehicles`}
      />,
    ];
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!idleData ? <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div> : idlingGraph()}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Machine Idling'}
        pageFilters={idleFilters}
        pageStates={{
          pageTargets: idleTarget,
          displayedAxis: displayedAxis,
          selectedSpecialView: selectedSpecialView,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {!idleData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              <StatsViewReportFiltersWrapper key={`statsviewReportIdleFilter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              {/* Sort By */}
              <StatsViewReportSortByWrapper key={`statsviewReportIdleSort`}>
                {displayControlComponents()}
              </StatsViewReportSortByWrapper>
            </React.Fragment>
          )}
          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Y-Axis Controls */}
                <Grid
                  item
                  xs={2}
                  lg={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '5px',
                      },
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      transformOrigin: 'center',
                      transform: 'rotate(270deg)',
                      margin: 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{m: 1}}>
                      <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                      <Select
                        sx={{padding: '3px'}}
                        size='small'
                        label='Measure by'
                        value={displayedAxis}
                        onChange={(e) => {
                          setDisplayedAxis(e.target.value);
                        }}
                      >
                        <MenuItem value={'Idle %'}>{'Idle %'}</MenuItem>
                        <MenuItem value={'Idle Time (hr)'}>{'Idle Time (hr)'}</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                {/* Graph */}
                <Grid item xs={10} lg={9} sx={{textAlign: 'center'}}>
                  {idlingGraph()}
                </Grid>
                <Grid item xs={10} lg={9} sx={{textAlign: 'center'}}>
                  <FormControl sx={{minWidth: '120px', m: 1}}>
                    <InputLabel>View by</InputLabel>
                    <Select
                      size='small'
                      label='View by'
                      value={selectedSpecialView}
                      onChange={(e) => {
                        setSelectedSpecialView(e.target.value);
                        if (timeAxes.includes(e.target.value)) {
                          if (idleFilters['idleVehicleSort'] == 'Alphabetical') {
                            setIdleFilters((values) => {
                              return {...values, 'idleVehicleSort': 'Chronological'};
                            });
                          }
                        } else {
                          if (idleFilters['idleVehicleSort'] == 'Chronological') {
                            setIdleFilters((values) => {
                              return {...values, 'idleVehicleSort': 'Alphabetical'};
                            });
                          }
                        }
                      }}
                    >
                      <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Days'}>
                        {'Days'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Weeks'}>
                        {'Weeks'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Months'}>
                        {'Months'}
                      </MenuItem>
                      <MenuItem disabled={compareByToggle} value={'Years'}>
                        {'Years'}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiIdling};
