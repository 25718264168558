import React, {useState, useEffect, useRef} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, deepCopy, isNumeric, machineTypeMapping, mergeLabelsAndData} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genTotalMachineUsage} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportSideControlWrapper,
  StatsViewReportGraphAndControlStyle,
} from '../dashboardUtils';
import {setFilterOptions, setCompareByDatesCache, setCompareByToggle} from '../dashboardSlice';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
  OutlinedInput,
  InputAdornment,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(annotationPlugin);
const timeAxes = ['Days', 'Weeks', 'Months', 'Years'];

function KpiMachineUsage(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const daysDiff = useSelector((state) => {
    return state.dashboard.daysDiff;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const activeDevices = useSelector((state) => {
    return state.dashboard.activeDevices;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const addToScorecardTrigger = useSelector((state) => {
    return state.dashboard.addToScorecardTrigger;
  });
  const selectedSubset = useSelector((state) => {
    return state.dashboard.selectedSubset;
  });
  const compareByDatesCache = useSelector((state) => {
    return state.dashboard.compareByDatesCache;
  });
  const compareByOperationsData = useSelector((state) => {
    return state.dashboard.compareByOperationsData;
  });
  const compareByToggle = useSelector((state) => {
    return state.dashboard.compareByToggle;
  });
  const comparisonLoading = useSelector((state) => {
    return state.dashboard.comparisonLoading;
  });

  const [datasets, setDatasets] = useState(null);
  const [datasetLabels, setDatasetLabels] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [hourDollarRate, setHourDollarRate] = useState('');
  const [fuelRate, setFuelRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  const [fuelRateInput, setFuelRateInput] = useState('');
  const [fuelCostInput, setFuelCostInput] = useState('');
  const [displayAverageMetrics, setDisplayAverageMetrics] = useState(false);
  const [selectedSpecialView, setSelectedSpecialView] = useState('Vehicle');
  const [taskFilterOptions, setTaskFilterOptions] = useState([]);
  const [addToScorecardOpened, setAddToScorecardOpened] = useState(null);
  const [usageFilters, setUsageFilters] = useState({
    usageVehicleSNs: [],
    usageVehicleType: [],
    usageVehicleCount: '25',
    usageVehicleSort: 'Desc',
  });
  const additionalInputRef = useRef();

  useEffect(() => {
    if (compareByToggle && timeAxes.includes(selectedSpecialView)) {
      setSelectedSpecialView('Vehicle');
      setDisplayAverageMetrics(false);
      setDisplayedAxis('Total');
    }
  }, [compareByToggle]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    vehicleSNDict,
    usageFilters,
    displayedAxis,
    activeDevices,
    hourDollarRate,
    fuelRate,
    fuelCost,
    selectedSpecialView,
    displayAverageMetrics,
    compareByToggle,
    compareByOperationsData,
  ]);

  useEffect(() => {
    // Setup task filter options
    if (operationsData != null) {
      const tasks = [];
      let taskIds = operationsData ? Object.keys(operationsData.tasksObject) : [''];
      if (compareByToggle && compareByOperationsData != null) {
        taskIds = [...new Set([...taskIds, ...Object.keys(compareByOperationsData.tasksObject)])];
      }
      taskIds.forEach((taskId) => {
        if (taskId == '') {
          tasks.push({value: taskId, label: 'No Task Found'});
        } else {
          let taskName = '';
          if (typeof taskConfigs[taskId] !== 'undefined') {
            taskName = taskConfigs[taskId].name;
          }
          tasks.push({value: taskId, label: taskName});
        }
      });
      tasks.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      setTaskFilterOptions(tasks);
    }
  }, [operationsData, compareByToggle, compareByOperationsData]);

  useEffect(() => {
    if (
      ((displayedAxis == 'Fuel' || displayedAxis == 'GHG') && !fuelRateInput) ||
      (displayedAxis == 'Cost' && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
    ) {
      if (additionalInputRef && additionalInputRef.hasOwnProperty('current') && additionalInputRef?.current) {
        additionalInputRef.current.scrollIntoView({behavior: 'smooth'});
      }
    }
  }, [displayedAxis]);

  useEffect(() => {
    const filterOptionsTemp = deepCopy(filterOptions);

    // Use chronological sorting for time axes, otherwise use alphabetical
    if (selectedSpecialView == null) {
      filterOptionsTemp.vehicles.usageVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    } else if (timeAxes.includes(selectedSpecialView)) {
      filterOptionsTemp.vehicles.usageVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Chronological', text: 'Chronological'},
      ];
    } else {
      filterOptionsTemp.vehicles.usageVehicleSort = [
        {value: 'Desc', text: 'Descending'},
        {value: 'Asc', text: 'Ascending'},
        {value: 'Alphabetical', text: 'Alphabetical'},
      ];
    }

    dispatch(setFilterOptions(filterOptionsTemp));
  }, [selectedSpecialView]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.fuelRate) {
      setFuelRate(parseFloat(props.pageStates?.fuelRate));
    } else {
      setFuelRate(0);
    }
    if (props.pageStates?.fuelCost) {
      setFuelCost(parseFloat(props.pageStates?.fuelCost));
      setHourDollarRate('');
    } else {
      setFuelCost(0);
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(parseFloat(props.pageStates?.hourDollarRate));
      setFuelCost(0);
    }
    if (props?.pageFilters) {
      setUsageFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
    if (props.pageStates?.selectedSpecialView) {
      setSelectedSpecialView(props.pageStates?.selectedSpecialView);
    } else {
      setSelectedSpecialView('Vehicle');
    }

    // Compare by page states
    if (props.pageStates?.compareByDateRange && props.pageStates?.compareByToggle) {
      dispatch(setCompareByDatesCache(props.pageStates?.compareByDateRange));
    }
    if (props.pageStates?.compareByToggle) {
      dispatch(setCompareByToggle(props.pageStates?.compareByToggle));
    }
    // If we are in a scorecard but compareByToggle is not set. Then we need to set it to false
    else if (props.hasOwnProperty('pageStates')) {
      dispatch(setCompareByToggle(false));
    }
  }, [props.pageStates, props.pageFilters]);

  useEffect(() => {
    if (addToScorecardOpened == null) {
      setAddToScorecardOpened(false);
    } else {
      setAddToScorecardOpened(true);
    }
  }, [addToScorecardTrigger]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setFuelCostInput('');
      setFuelCost(0);
      setHourDollarRate(e.target.value);
    }
  }

  function handleFuelRateChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setFuelRateInput(value);
      // Always save value in metric
      setFuelRate(unitsSystem == 'imperial' ? parseFloat(value * 3.785) : parseFloat(value));
    }
  }

  function handleFuelCostChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setHourDollarRate('');
      // Always save value in metric
      setFuelCostInput(value);
      setFuelCost(unitsSystem == 'imperial' ? parseFloat(value / 3.785) : parseFloat(value));
    }
  }

  function unionLabelsAndDataTasks(opsData, compareByOpsData, excludedKeys = []) {
    const opsDataCopy = deepCopy(opsData);
    const compareByOpsDataCopy = deepCopy(compareByOpsData);
    // Do for Vehicles
    const opsDataKeys = Object.keys(opsDataCopy);
    const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);

    opsDataKeys.forEach((opsDataKey) => {
      if (!compareByOpsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(opsDataCopy[opsDataKey]);
        // In this case innerObjKey is the zone key
        Object.keys(objZeros).forEach((innerObjKey) => {
          Object.keys(objZeros[innerObjKey]).forEach((secondInnerObjKey) => {
            Object.keys(objZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
              objZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
            });
          });
        });
        compareByOpsDataCopy[opsDataKey] = objZeros;
      }
    });
    compareByOpsDataKeys.forEach((opsDataKey) => {
      if (!opsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
        // In this case innerObjKey is the zone key
        Object.keys(objZeros).forEach((innerObjKey) => {
          Object.keys(objZeros[innerObjKey]).forEach((secondInnerObjKey) => {
            Object.keys(objZeros[innerObjKey][secondInnerObjKey]).forEach((thirdInnerObjKey) => {
              objZeros[innerObjKey][secondInnerObjKey][thirdInnerObjKey] = 0;
            });
          });
        });
        opsDataCopy[opsDataKey] = objZeros;
      }
    });

    return [opsDataCopy, compareByOpsDataCopy];
  }

  function unionLabelsAndDataVehicles(opsData, compareByOpsData, excludedKeys = []) {
    const opsDataCopy = deepCopy(opsData);
    const compareByOpsDataCopy = deepCopy(compareByOpsData);

    // Do for Vehicles
    const opsDataKeys = Object.keys(opsDataCopy);
    const compareByOpsDataKeys = Object.keys(compareByOpsDataCopy);

    opsDataKeys.forEach((opsDataKey) => {
      if (!compareByOpsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(opsDataCopy[opsDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          }
        });
        compareByOpsDataCopy[opsDataKey] = objZeros;
      }
    });
    compareByOpsDataKeys.forEach((opsDataKey) => {
      if (!opsDataKeys.includes(opsDataKey)) {
        const objZeros = deepCopy(compareByOpsDataCopy[opsDataKey]);
        Object.keys(objZeros).forEach((innerObjKey) => {
          if (!excludedKeys.includes(innerObjKey)) {
            objZeros[innerObjKey] = 0;
          }
        });
        opsDataCopy[opsDataKey] = objZeros;
      }
    });

    return [opsDataCopy, compareByOpsDataCopy];
  }

  function generateData() {
    const opsDataCopy = deepCopy(operationsData);
    const compareByOpsDataCopy = deepCopy(compareByOperationsData);

    if (compareByToggle && compareByOperationsData != null) {
      const [newOpsVehiclesObject, newCompareByOpsVehiclesObject] = unionLabelsAndDataVehicles(
        operationsData.vehiclesObject,
        compareByOperationsData.vehiclesObject,
        ['type', 'vehicleName']
      );
      opsDataCopy.vehiclesObject = newOpsVehiclesObject;
      compareByOpsDataCopy.vehiclesObject = newCompareByOpsVehiclesObject;

      // Need to make this function
      const [newOpsTasksObject, newCompareByOpsTasksObject] = unionLabelsAndDataTasks(
        operationsData.tasksObject,
        compareByOperationsData.tasksObject
      );
      opsDataCopy.tasksObject = newOpsTasksObject;
      compareByOpsDataCopy.tasksObject = newCompareByOpsTasksObject;
    }

    // Generate data
    const combinedMachineUsageData = genTotalMachineUsage(
      opsDataCopy,
      usageFilters,
      daysDiff,
      vehicleSNDict,
      taskConfigs,
      activeDevices,
      unitsSystem,
      hourDollarRate,
      fuelRate,
      fuelCost,
      displayAverageMetrics,
      displayedAxis,
      opsDataCopy
    );

    let compareByCombinedMachineUsageData;
    if (compareByToggle && compareByOperationsData != null) {
      compareByCombinedMachineUsageData = genTotalMachineUsage(
        compareByOpsDataCopy,
        usageFilters,
        daysDiff,
        vehicleSNDict,
        taskConfigs,
        activeDevices,
        unitsSystem,
        hourDollarRate,
        fuelRate,
        fuelCost,
        displayAverageMetrics,
        displayedAxis,
        opsDataCopy,
        true
      );

      combinedMachineUsageData[selectedSpecialView].datasets = [
        ...combinedMachineUsageData[selectedSpecialView].datasets,
        ...compareByCombinedMachineUsageData[selectedSpecialView].datasets,
      ];
    }
    // Set data
    const selectedDataset = combinedMachineUsageData[selectedSpecialView];
    if (Object.keys(operationsData.vehiclesObject).length > 0) {
      setDatasetLabels(selectedDataset.labels);
    } else {
      setDatasetLabels(null);
    }

    // Select datasets based on displayed axis
    const tempDatasets = [];
    selectedDataset.datasets.forEach((setObj) => {
      if (setObj.id == displayedAxis) {
        tempDatasets.push(setObj);
      }
    });
    setDatasets(tempDatasets);
  }

  function handleVehiclesFilters(value, selectName) {
    setUsageFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function machineUsageGraph(rateCostText = null) {
    // Get display values based on which axis is selected
    const [graphTitleBase, axisTitle] = displayValuesArbitration();

    // Generate title based on filters selected
    let graphTitle = graphTitleBase;
    if (usageFilters.usageVehicleType.length > 0) {
      const selectedTypeText = `Vehicle Types`;
      if (usageFilters.usageVehicleType.length == 1) {
        graphTitle += ` - ${machineTypeMapping[parseInt(usageFilters.usageVehicleType[0])]}`;
      } else if (usageFilters.usageVehicleType.length > 1) {
        graphTitle += ` - Multiple ${selectedTypeText} Selected (${usageFilters.usageVehicleType.length})`;
      }
    }

    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{
            labels: datasetLabels,
            datasets: datasets,
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              subtitle: {
                display: rateCostText != null,
                text: rateCostText,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                title: {
                  text: axisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                display: displayedAxis != 'Avg',
                position: 'left',
              },
              x: {
                ticks: {
                  autoSkip: datasetLabels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 0,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: timeAxes.includes(selectedSpecialView)
                    ? `${selectedSpecialView.slice(0, -1)}`
                    : `${selectedSpecialView}s`,
                  display: !!props?.graphOnly,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function displayValuesArbitration() {
    let graphTitle;
    let axisTitle;

    switch (displayedAxis) {
      case 'Total':
        graphTitle = 'Total Machine Usage';
        axisTitle = 'Usage Hours';
        break;
      case 'Distance':
        graphTitle = 'Total Machine Travel Distance';
        axisTitle = unitsSystem == 'imperial' ? 'Distance (mi)' : 'Distance (km)';
        break;
      case 'Avg':
        // Deprecated, but keeping for old scorecards that may need it
        graphTitle = 'Average Machine Hours / Day';
        axisTitle = 'Hours';
        break;
      case 'Fuel':
        graphTitle = 'Total Fuel Consumption';
        axisTitle = unitsSystem == 'imperial' ? 'Fuel Consumption (Gal)' : 'Fuel Consumption (L)';
        break;
      case 'GHG':
        graphTitle = 'Total GHG emissions';
        axisTitle = 'Emissions (Tonnes)';
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          graphTitle = 'Total Machine Operating Cost';
          axisTitle = 'Machine Operating Cost ($)';
        } else {
          graphTitle = 'Total Fuel Cost';
          axisTitle = 'Fuel Cost ($)';
        }
        break;
      default:
        graphTitle = 'Total Machine Hours';
        axisTitle = 'Usage Hours';
    }

    return [graphTitle, axisTitle];
  }

  function addCompareByVehicles(ogVehicles) {
    let combined = deepCopy(ogVehicles);
    if (compareByToggle && compareByOperationsData != null) {
      const mainSetVehicleSN = ogVehicles.map((option) => {
        return option.value;
      });
      combined = [
        ...ogVehicles,
        ...Object.keys(compareByOperationsData.vehiclesObject)
          .filter((key) => {
            return !mainSetVehicleSN.includes(key);
          })
          .map((opt) => {
            return {'text': vehicleSNDict[opt].name, 'value': opt};
          }),
      ];
      combined = [...combined].sort((optA, optB) => {
        return optA.text.localeCompare(optB.text);
      });
    }
    return combined;
  }

  function filterControlComponents() {
    if (selectedSpecialView !== 'Task') {
      /* Vehicle & Vehicle Type Filter */
      return [
        <IntelliSearchSelect
          allNoneEnabled={true}
          label='Vehicles'
          id='dashboard-vehicles-usageVehicleSNs-filter'
          key='dashboard-vehicles-usageVehicleSNs-filter'
          name='usageVehicleSNs'
          multiple={true}
          search={true}
          value={usageFilters.usageVehicleSNs}
          options={addCompareByVehicles(filterOptions.vehicles.vehicleSNs).map((opt) => {
            return {'label': opt.text, 'value': opt.value};
          })}
          onChange={(s) => {
            handleVehiclesFilters(s, 'usageVehicleSNs');
          }}
          placeholder='Filter for Vehicles'
        />,
        <IntelliSearchSelect
          allNoneEnabled={true}
          label='Vehicle Type'
          id='dashboard-vehicles-usageVehicleType-filter'
          key='dashboard-vehicles-usageVehicleType-filter'
          name='usageVehicleType'
          multiple={true}
          search={true}
          value={usageFilters.usageVehicleType}
          options={filterOptions.vehicles.vehicleType.map((opt) => {
            return {'label': opt.text, 'value': opt.value};
          })}
          onChange={(s) => {
            handleVehiclesFilters(s, 'usageVehicleType');
          }}
          placeholder='Filter for Vehicle Type'
        />,
      ];
    } else {
      /* Task Filter */
      return [
        <IntelliSearchSelect
          allNoneEnabled={true}
          label='Tasks'
          id='dashboard-vehicles-usageVehicleSNs-filter'
          key='dashboard-vehicles-usageVehicleSNs-filter'
          name='usageVehicleSNs'
          multiple={true}
          search={true}
          value={usageFilters.usageVehicleSNs} // Use same strucutre for task and vehicle, just populate with different data
          options={taskFilterOptions}
          onChange={(s) => {
            handleVehiclesFilters(s, 'usageVehicleSNs');
          }}
          placeholder='Filter for Tasks'
        />,
      ];
    }
  }

  function displayControlComponents() {
    return [
      <IntelliSearchSelect
        label='Sort By'
        id='dashboard-vehicles-usageVehicleSort-filter'
        key='dashboard-vehicles-usageVehicleSort-filter'
        name='usageVehicleSort'
        multiple={false}
        value={usageFilters.usageVehicleSort}
        options={filterOptions.vehicles.usageVehicleSort.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'usageVehicleSort');
        }}
        placeholder='Sorting method'
      />,
      <IntelliSearchSelect
        label={`${selectedSpecialView || 'Vehicle'} Count`}
        id='dashboard-vehicles-usageVehicleCount-filter'
        key='dashboard-vehicles-usageVehicleCount-filter'
        name='usageVehicleCount'
        multiple={false}
        value={usageFilters.usageVehicleCount}
        // Use efficiencyZoneCount for options so its just the generic count options
        options={filterOptions.efficiency.efficiencyZoneCount.map((opt) => {
          return {'label': opt.text, 'value': opt.value};
        })}
        onChange={(s) => {
          handleVehiclesFilters(s, 'usageVehicleCount');
        }}
        placeholder={`Show All ${selectedSpecialView || 'Vehicle'}`}
      />,
    ];
  }

  function additionalInputComponents() {
    return (
      <React.Fragment>
        <FormControl sx={{width: '100%'}}>
          <InputLabel shrink>Fuel Rate</InputLabel>
          <OutlinedInput
            sx={displayedAxis.includes('Cost') ? {mb: 2} : {}}
            endAdornment={<InputAdornment position='end'>{unitsSystem == 'imperial' ? 'Gal' : 'L'}/Hr</InputAdornment>}
            notched
            label='Fuel Rate'
            inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
            size='small'
            onChange={handleFuelRateChange}
            value={fuelRateInput}
          />
        </FormControl>
        {displayedAxis.includes('Cost') && (
          <React.Fragment>
            <FormControl sx={{width: '100%'}}>
              <InputLabel shrink>Fuel Cost</InputLabel>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position='end'>$/{unitsSystem == 'imperial' ? 'Gal' : 'L'}</InputAdornment>
                }
                notched
                label='Fuel Cost'
                size='small'
                onChange={handleFuelCostChange}
                value={fuelCostInput}
              />
            </FormControl>
            <Divider orientation='horizontal'>or</Divider>
            <FormControl sx={{width: '100%'}}>
              <InputLabel shrink>Hour Rate</InputLabel>
              <OutlinedInput
                endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
                notched
                label='Hour Rate'
                size='small'
                onChange={handleHourDollarRateChange}
                value={hourDollarRate}
              />
            </FormControl>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  // Check if loading
  if (loading || (comparisonLoading && compareByToggle)) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    let rateCostText = '';
    switch (displayedAxis) {
      case 'Fuel':
      case 'GHG':
        const fuelRateString =
          unitsSystem == 'imperial'
            ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
            : `${fuelRate.toFixed(2)} L/Hr`;
        rateCostText += `Fuel Rate: ${fuelRateString}`;
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          rateCostText += `Machine Operating Rate: $${parseFloat(hourDollarRate).toFixed(2)}/hr`;
        } else {
          const fuelRateString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
              : `${fuelRate.toFixed(2)} L/Hr`;
          rateCostText += `Fuel Rate: ${fuelRateString}`;
          const fuelCostString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelCost * 3.785).toFixed(2))} $/Gal`
              : `${fuelCost.toFixed(2)} $/L`;
          rateCostText += ` | Fuel Cost: ${fuelCostString}`;
        }
        break;
      default:
        rateCostText = null;
    }

    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset={selectedSubset} />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!datasetLabels ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            machineUsageGraph(rateCostText)
          )}
        </div>
      </React.Fragment>
    );
  }

  // Main return
  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Hidden Add to Scorecard*/}
      <ScorecardSelectHolder
        autofillPageName={'Machine Usage'}
        pageFilters={usageFilters}
        pageStates={{
          displayedAxis: displayedAxis,
          fuelRate: fuelRate,
          fuelCost: fuelCost,
          hourDollarRate: hourDollarRate,
          selectedSpecialView: selectedSpecialView,
          compareByToggle: compareByToggle,
          compareByDateRange: compareByDatesCache,
        }}
        hidden={true}
        opened={addToScorecardOpened}
        updateExternalOpenedState={setAddToScorecardOpened}
      />

      {!datasetLabels ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* In Small screen mode Filters and Sort by display */}
          {smallScreen && (
            <React.Fragment>
              <StatsViewReportFiltersWrapper key={`vehicleUsageFilter`}>
                {filterControlComponents()}
              </StatsViewReportFiltersWrapper>
              <StatsViewReportSortByWrapper key={`vehicleUsageSort`}>
                {displayControlComponents()}
              </StatsViewReportSortByWrapper>
            </React.Fragment>
          )}

          {/* Graph and XY Axis Control */}
          <Grid container spacing={2} sx={{padding: 1}}>
            <Grid item xs={smallScreen ? 12 : 7} md={8} lg={9}>
              <Box sx={StatsViewReportGraphAndControlStyle}>
                {/* Y-Axis Controls */}
                <Grid
                  item
                  xs={2}
                  lg={1}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      '.MuiInputBase-input': {
                        padding: '5px',
                      },
                      display: 'flex',
                      flexDirection: 'row',
                      position: 'relative',
                      transformOrigin: 'center',
                      transform: 'rotate(270deg)',
                      margin: 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <FormControl sx={{m: 1}}>
                      <InputLabel sx={{maxWidth: 'none'}}>Measure by</InputLabel>
                      {/* Y Axis Control */}
                      {timeAxes.includes(selectedSpecialView) && displayAverageMetrics ? (
                        <Select
                          sx={{padding: '3px'}}
                          size='small'
                          label='Measure by'
                          value={displayedAxis}
                          onChange={(e) => {
                            setDisplayedAxis(e.target.value);
                          }}
                        >
                          <MenuItem value={'Avg Hours'}>{'Average Hours'}</MenuItem>
                          <MenuItem value={'Avg Distance'}>{'Average Distance'}</MenuItem>
                          <MenuItem value={'Avg Fuel'}>{'Average Fuel'}</MenuItem>
                          <MenuItem value={'Avg Cost'}>{'Average Cost'}</MenuItem>
                          <MenuItem value={'Avg GHG'}>{'Average GHG'}</MenuItem>
                        </Select>
                      ) : (
                        <Select
                          sx={{padding: '3px'}}
                          size='small'
                          label='Measure by'
                          value={displayedAxis}
                          onChange={(e) => {
                            setDisplayedAxis(e.target.value);
                          }}
                        >
                          <MenuItem value={'Total'}>{'Total Hours'}</MenuItem>
                          <MenuItem value={'Distance'}>{'Total Distance'}</MenuItem>
                          <MenuItem value={'Fuel'}>{'Fuel'}</MenuItem>
                          <MenuItem value={'Cost'}>{'Cost'}</MenuItem>
                          <MenuItem value={'GHG'}>{'GHG'}</MenuItem>
                        </Select>
                      )}
                    </FormControl>
                  </Box>
                </Grid>
                {/* Graph */}
                <Grid item xs={10} lg={9} sx={{textAlign: 'center', marginTop: 'auto'}}>
                  {machineUsageGraph()}
                </Grid>
                {/* Y-Axis Additional Input  IN SMALL SCREEN MODE*/}
                {smallScreen && (
                  <Grid
                    item
                    xs={4.5}
                    sx={{display: 'flex', justifyContent: 'center', padding: '10px'}}
                    ref={additionalInputRef}
                  >
                    {(displayedAxis.includes('Fuel') ||
                      displayedAxis.includes('Cost') ||
                      displayedAxis.includes('GHG')) && (
                      <Box
                        sx={{
                          '.MuiInputBase-input': {
                            padding: '5px',
                          },
                          display: 'flex',
                          flexDirection: 'column',
                          padding: '10px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          boxShadow:
                            ((displayedAxis.includes('Fuel') || displayedAxis.includes('GHG')) && !fuelRateInput) ||
                            (displayedAxis.includes('Cost') && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
                              ? '0px 3px 10px 0px #ff9966'
                              : '0px 3px 10px 0px #aaaaaa',
                          borderRadius: '4px',
                        }}
                      >
                        {additionalInputComponents()}
                      </Box>
                    )}
                  </Grid>
                )}
                {/* X-Axis Control */}
                <Grid item xs={smallScreen ? 7.5 : 12}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: smallScreen ? 'column' : 'row',
                      justifyContent: 'center',
                      textAlign: 'center',
                      padding: 1,
                    }}
                  >
                    <FormControl sx={{minWidth: '120px'}}>
                      <InputLabel>View by</InputLabel>
                      <Select
                        size='small'
                        label='View by'
                        value={selectedSpecialView === null ? 'Vehicle' : selectedSpecialView}
                        onChange={(e) => {
                          if (timeAxes.includes(e.target.value)) {
                            setSelectedSpecialView(e.target.value);
                            if (usageFilters['usageVehicleSort'] == 'Alphabetical') {
                              setUsageFilters((values) => {
                                return {...values, 'usageVehicleSort': 'Chronological'};
                              });
                            } else {
                              setUsageFilters((values) => {
                                return {
                                  ...values,
                                  'usageVehicleSNs': usageFilters.usageVehicleSNs.filter((sn) => {
                                    return Object.keys(vehicleSNDict).includes(sn);
                                  }),
                                };
                              });
                            }

                            // Other, task, vehicles
                          } else if (['Task'].includes(e.target.value)) {
                            setSelectedSpecialView(e.target.value);
                            if (usageFilters['usageVehicleSort'] == 'Chronological') {
                              setUsageFilters((values) => {
                                return {...values, 'usageVehicleSort': 'Alphabetical'};
                              });
                            } else {
                              setUsageFilters((values) => {
                                return {
                                  ...values,
                                  'usageVehicleSNs': usageFilters.usageVehicleSNs.filter((sn) => {
                                    return Object.keys(taskConfigs).includes(sn);
                                  }),
                                };
                              });
                            }

                            // Reset average selection
                            setDisplayAverageMetrics(false);
                            setDisplayedAxis('Total');

                            // Default
                          } else {
                            setSelectedSpecialView('Vehicle'); // Default to vehicle

                            if (usageFilters['usageVehicleSort'] == 'Chronological') {
                              setUsageFilters((values) => {
                                return {...values, 'usageVehicleSort': 'Alphabetical'};
                              });
                            } else {
                              setUsageFilters((values) => {
                                return {
                                  ...values,
                                  'usageVehicleSNs': usageFilters.usageVehicleSNs.filter((sn) => {
                                    return Object.keys(vehicleSNDict).includes(sn);
                                  }),
                                };
                              });
                            }

                            // Reset average selection
                            setDisplayAverageMetrics(false);
                            setDisplayedAxis('Total');
                          }
                        }}
                      >
                        <MenuItem value={'Vehicle'}>{'Vehicle'}</MenuItem>
                        <MenuItem disabled={compareByToggle} value={'Days'}>
                          {'Days'}
                        </MenuItem>
                        <MenuItem disabled={compareByToggle} value={'Weeks'}>
                          {'Weeks'}
                        </MenuItem>
                        <MenuItem disabled={compareByToggle} value={'Months'}>
                          {'Months'}
                        </MenuItem>
                        <MenuItem disabled={compareByToggle} value={'Years'}>
                          {'Years'}
                        </MenuItem>
                        <MenuItem value={'Task'}>{'Task'}</MenuItem>
                      </Select>
                    </FormControl>
                    {/* Display Average Line Control */}
                    {timeAxes.includes(selectedSpecialView) && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => {
                              setDisplayAverageMetrics(!displayAverageMetrics);

                              // Default to average hours on toggle
                              !displayAverageMetrics ? setDisplayedAxis('Avg Hours') : setDisplayedAxis('Total');
                            }}
                            checked={displayAverageMetrics}
                          />
                        }
                        label={
                          `Show Average per Vehicle` // Each time bucket calculated per vehicle basis
                        }
                      />
                    )}
                  </Box>
                </Grid>
              </Box>
            </Grid>
            {!smallScreen && (
              <Grid
                item
                xs={5}
                md={4}
                lg={3}
                sx={{
                  display: smallScreen ? 'none' : 'block',
                }}
              >
                <StatsViewReportSideControlWrapper
                  filterControlComponents={filterControlComponents()}
                  displayControlComponents={displayControlComponents()}
                  additionalInputs={additionalInputComponents()}
                  additionalInputsTooltip={<KpiTooltip selectedSubset={'kpiMachineUsageInput'} />}
                  additionalInputWarningBorder={
                    ((displayedAxis.includes('Fuel') || displayedAxis.includes('GHG')) && !fuelRateInput) ||
                    (displayedAxis.includes('Cost') && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
                  }
                />
              </Grid>
            )}
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiMachineUsage};
